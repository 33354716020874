@import "~assets/scss/variables";

.react-datepicker__month-container {
  font-size: 16px;
}

.react-datepicker__header {
  background-color: $white;
  border: none;
}

.react-datepicker__day {
  border-radius: 4px;
  border: solid 1px $white;
  transition: color 0.1s,
    background-color 0.1s ease-in-out,
    border-color 0.1s ease-in-out;
}

.react-datepicker__day--weekend {
  color: $gray-text;
}

.react-datepicker__day--outside-month {
  color: $gray-1;
}

.react-datepicker__day--in-range {
  color: $white;
}

.react-datepicker__day--today {
  color: $orange;
  background-color: transparent;
  font-weight: 400;

  &:hover {
    background-color: $orange;
  }
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: $white;
  color: $orange;
  border: solid 1px $orange;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 4px;
  background-color: $orange;
  color: $white;
  border: solid 1px $orange;
  transition: color 0.1s, background 0.1s ease-in-out;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 4px;
  border: solid 1px $orange;
  background-color: $white;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: $white;
  border: solid 1px $orange;
  color: $orange;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range) {
  background-color: $white;
  color: $orange;
}


.react-datepicker__navigation-icon {
  display: none;
}

.react-datepicker__navigation {
  background: url(./arrowBlack.svg) no-repeat center center;
}

.react-datepicker__navigation--next {
  transform: rotate(180deg);
}

.react-datepicker__current-month {
  border-bottom: solid 1px $gray-1;
  padding-bottom: 15px !important;
  font-family: "SFProText";
  font-size: 16px;
  font-weight: 500;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 48px;
  height: 32px;
  padding: 3px 0;
  margin: 0 0 4px 0;
}