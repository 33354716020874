@import "~assets/scss/variables";

.body {
  width: 100%;
  min-height: calc(100vh - 259px);

  &.mobile {
    padding: 10px;
  }
}
