@import "~assets/scss/variables";

@mixin link($color: $gray-text) {
  color: $color;
  border-bottom: solid 1px $color;
  cursor: pointer;
}

@mixin button() {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  height: 40px;
  border-radius: 4px;
  padding: 0 18px;
  background-color: $orange;
  color: $white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.1s, background 0.1s ease-in-out;

  & svg {
    transition: fill 0.1s ease-in-out;
  }

  &:hover {
    background-color: $darkblue-1;
  }

  &:active {
    background-color: $darkblue-2;
  }

  &.mobile {
    height: 30px;
    padding: 7px 12px;
  }

  &:disabled {
    background-color: inherit;
    color: $gray-1;
  }
}

@mixin o-button() {
  @include button();
  color: $darkblue;
  background-color: $white;
  border: solid 1px $gray-1;

  &:hover {
    background-color: $orange;
    color: $white;
    border: none;
  }

  &:active {
    background-color: $orange;
    border: none;
  }
}

@mixin buttonSmall() {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 4px;
  background-color: $white;
  color: $darkblue;
  border: solid 1px $orange;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.1s, background 0.1s ease-in-out;

  &:hover {
    background-color: $orange;
    color: $white;
  }
}

@mixin overlay() {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba($darkblue-1, 0.7);
  z-index: 999;
}

@mixin boxShadow() {
  box-shadow: 0 10px 22px 0 rgba($darkblue-1, 0.3);
}
