@import "~assets/scss/variables";
@import "~assets/scss/mixins";

.tabs {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  margin-bottom: 26px;
}

.tab {
  height: 44px;
  padding: 9px 18px;
  border-radius: 4px;
  border: solid 1px $gray-1;
  background-color: $white;
  color: $darkblue;
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  transition: background-color 0.1s, color 0.1s, border 0.1s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: $darkblue-2;
    color: $white;
    border: solid 1px $darkblue-2;
  }

  &.active {
    background-color: $darkblue;
    color: $white;
    border: solid 1px $darkblue;
  }
}

.items {
  position: relative;
  min-height: 200px;
}

.content {
  width: 100%;
  height: 100%;
}