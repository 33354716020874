@import "~assets/scss/variables";

.wrapper {
  padding: 60px;
}

.title {
  margin-bottom: 24px;
  font-size: 42px;
  font-weight: 700;
  color: $darkblue;
}

.subTitle {
  margin-bottom: 21px;
  font-size: 14px;
  font-weight: 500;
  color: $darkblue-2;
}

.addressesList {
  margin-bottom: 43px;
}

.addressItem {
  display: flex;
  gap: 11px;
  padding: 15px;
  margin-bottom: 15px;
  border: solid 1px $gray-2;
  border-radius: 4px;
  background-color: $gray-2;
  color: $darkblue-1;
  cursor: pointer;
  transition: border 0.1s ease-in-out;

  &.active {
    border: solid 1px $darkblue-2;
  }
}

.radio {
  flex-shrink: 0;
  position: relative;
  width: 18px;
  height: 18px;
  border: solid 1px $gray-1;
  border-radius: 50%;
  background-color: $gray;

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    left: 3px;
    top: 3px;
    background-color: $gray;
    border-radius: 50%;
    transition: background-color 0.1s ease-in-out;
  }
}

.active .radio:before {
  background-color: $darkblue-1;
}

.inputGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 34px;
}

.input {
  width: 100%;
}

.addButton {
  & svg {
    fill: $white;
  }
}

.closeResultBlock {
  display: flex;
  justify-content: center;
  width: 100%;
}

.closeButton {
  width: 346px;

  &:disabled {
    background-color: $gray;
  }
}

.addAddressTitle {
  margin-bottom: 15px;
  font-size: 14px;
  color: $darkblue-2;
}

.tablet {
  padding: 40px 10px;

  & .title {
    margin-bottom: 20px;
    font-size: 32px;
  }
}

.mobile {
  padding: 40px 10px;

  & .title {
    margin-bottom: 10px;
    font-size: 22px;
  }

  & .inputGroup {
    flex-direction: column;
  }

  & .addButton {
    width: 100%;
    height: 44px;
  }

  & .closeButton {
    width: 100%;
    height: 44px;
  }
}