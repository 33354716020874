@import "~assets/scss/variables";

.offer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 0 4px 0 18px;
  border-bottom: solid 1px $gray;
  color: $darkblue;
  font-size: 12px;
  font-weight: 500;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: $gray-2;
  }

  &.inStock {
    background-color: $green-light;
  }

  &.inTransit {
    background-color: $blue-light;
  }

  &>div {
    width: 100%;
  }
}

.quantityIcon {
  position: relative;

  &:hover {
    & .providerPopup {
      display: flex;
    }
  }
}

.price {
  font-weight: 800;
}

.cart {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.inCart {
  display: flex;
  align-items: center;
  width: 40px;

  svg {
    margin-right: 6px;
    fill: $orange;
  }
}

.mobile,
.tablet {
  height: 44px;
  gap: 7px;
  padding: 7px 14px;

  & .quantity {
    font-size: 11px;
  }

  & .inCart {
    font-size: 12px;
  }

  & .existCol {
    width: 180px;
  }

  & .deliveryText {
    font-size: 11px;
    color: $gray-text;
    white-space: nowrap;
  }

  & .buyBlock {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px;
  }
}