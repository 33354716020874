@import "~assets/scss/variables";
@import "~assets/scss/mixins";
.button {
  width: 80px;
  position: relative;
  @include buttonSmall();
}

.mobile,
.tablet {
  height: 30px;
  width: 30px;
  padding: 0;
  background-color: $orange;

  & svg {
    fill: $white;
  }
}
