@import "~assets/scss/variables";
@import "~assets/scss/mixins";


.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    background-color: $white;
    min-height: calc(100vh - 172px);
    border-radius: 6px;
    overflow: hidden;
    background-color: $white;
    color: $darkblue;

    &>div {
        width: 50%;
    }

}

.leftBlock {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    margin-bottom: 12px;
    font-size: 47px;
    font-weight: 800;
}

.rightBlock {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 8px 63px;
    background-color: $gray-2;

    & .title {
        margin: 0;
        padding-bottom: 24px;
        font-size: 36px;
    }
}

.image {
    img {
        max-width: 100%;
    }
}

.mobile,
.tablet {
    padding: 0 0 40px 0;

    & main {
        border-radius: 0;
    }

    .leftBlock {
        width: 100%;
    }
}