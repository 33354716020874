@import "~assets/scss/variables";
@import "~assets/scss/mixins";

.wrapper {
  color: $darkblue;
}

.cartBlock,
.relatedBlock {
  padding: 24px 32px;
}

.title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 18px;
}

.product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
}

.info {
  width: 350px;
}

.quantity {
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
}

.price {
  font-size: 12px;
  font-weight: 800;
  white-space: nowrap;
}

.priceBlock {
  display: flex;
  align-items: center;
  gap: 18px;
}

.productName {
  margin: 18px 0;
  padding-bottom: 18px;
  font-size: 14px;
  font-weight: 500;
  border-bottom: solid 1px $gray;
}

.checkoutBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonGroup {
  display: flex;
  gap: 12px;
}

.outlineButton,
.button {
  height: 44px;
  padding: 0 28px;
  font-size: 18px;
}

.button {
  @include button();
  height: 44px;
  font-size: 18px;
  border: none;
}

.outlineButton {
  color: $gray-text;
  background-color: $white;
  border: solid 1px $gray-1;

  &:hover {
    color: $white;
  }
}

.cartLink {
  color: $blue;
  border-color: $blue;
}

.relatedBlock {
  background-color: $gray;
}

.mobile,
.tablet {

  .cartBlock,
  .relatedBlock {
    padding: 0;
  }

  .title {
    padding: 7px 14px 7px 14px;
    font-size: 16px;
  }

  .info {
    width: 100%;
    padding: 0 14px;
  }

  .bottomBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 14px;
  }

  & .leftBlock {
    display: flex;
    align-items: center;
    gap: 18px;
  }

  .priceBlock {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    gap: 0;
  }

  .delivery {
    color: $gray-text;
    font-size: 11px;
    font-weight: 400;
  }

  .product {
    flex-direction: column;
  }

  .productName {
    padding: 0 14px 7px 14px;
    font-size: 12px;
  }

  .checkoutBlock {
    padding: 13px 14px;
    flex-direction: column;
    font-size: 14px;
    box-shadow: 0 8px 22px 0 rgba($darkblue, 30%);
  }

  .inCart {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    margin-bottom: 14px;
  }

  .buttonBlock {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .outlineButton,
  .button {
    height: 44px;
    width: 100%;
    font-size: 14px;
  }

  .button {
    margin-bottom: 8px;
  }
}

.tablet {
  & .title {
    padding: 25px 14px 7px 14px;
    font-size: 16px;
  }

  & .productInfo {
    padding: 0;
  }
}