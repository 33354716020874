@import "./variables";
@import "./vendor/react-datepicker";

@font-face {
  font-family: "SFProText";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/SF-Pro-Text-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "SFProText";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/SF-Pro-Text-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "SFProText";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/SF-Pro-Text-Semibold.woff2") format("woff2");
}

@font-face {
  font-family: "SFProText";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/SF-Pro-Text-Black.woff2") format("woff2");
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "SFProText"; //, sans-serif
  font-size: 13px;
  line-height: 1.25;
  color: $gray-text;
  min-width: 320px;
  position: relative;
  overflow-x: hidden;
}

html,
body {
  background: $gray;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: $darkblue;
  border-bottom: solid 1px $darkblue;
}

input,
textarea {
  outline: 0;

  &::placeholder {
    color: $gray-text;
    font-family: inherit;
  }
}

button {
  background: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.noScroll,
.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

.ReactModal__Body--open .scrollbar_compensate {
  width: calc(100% - 17px);
}
