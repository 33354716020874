@import "~assets/scss/variables";

.filter {
  border-bottom: solid 1px $gray-2;
  max-height: calc(100vh - 215px);
  overflow-y: auto;
}

.item {
  padding: 0 18px;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: $gray-2;
  }
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  padding: 6px 0;
}

.title {
  padding: 8px 18px;
  font-size: 14px;
  font-weight: 600;
  color: $darkblue;
}

.search {
  padding: 0 18px;
  margin-bottom: 8px;
}

.fromPrice {
  font-size: 10px;
  color: $gray-text;
}

.showMore {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 32px;

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 12px;
    cursor: pointer;

    &:after {
      content: "";
      right: 10px;
      display: block;
      width: 16px;
      height: 16px;
      background: url(~assets/img/svg/arrowDown.svg) center center no-repeat;
      transition: transform 0.1s ease-in-out;
    }
  }

  &.shown button:after {
    transform: scale(1, -1);
  }
}
