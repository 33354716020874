@import "~assets/scss/variables";

.wrapper {
  width: 494px;

  & form {
    width: 100%;
  }

  &.mobile {
    width: auto;
    padding: 10px;
  }
}

.title {
  margin-bottom: 12px;
  font-size: 47px;
  font-weight: 800;
}

.subTitle {
  margin-bottom: 45px;
  font-size: 19px;
  font-weight: 500;
}

.error {
  margin-bottom: 10px;
  color: $red;
}

.input {
  margin-bottom: 20px;
}

.rememberBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 45px;
}

.passwordRecovery {
  font-size: 14px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.enter {
  width: 100%;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 800;
}

.register {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 9px 18px;
  background-color: $gray;
  font-size: 15px;
  font-weight: 500;

  & a {
    border: none;
    color: $orange;
  }
}

.authLink {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 28px;
  font-size: 15px;
  font-weight: 600;
  border: none;
  color: $darkblue-1;

  svg {
    stroke: $darkblue-1;
    transform: scale(0.8);
  }
}

.tablet {
  & .title {
    font-size: 40px;
  }
  & .subTitle {
    font-size: 16px;
  }
}

.mobile {
  & .title {
    font-size: 32px;
  }

  & .subTitle {
    font-size: 14px;
  }

  & .enter {
    height: 44px;
  }
}

