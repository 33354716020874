@import "~assets/scss/variables";

.title {
  padding-bottom: 18px;
  font-size: 18px;
  font-weight: 600;
  color: $darkblue;
}

.item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;

  &:not(:last-child) {
    border-bottom: solid 1px $gray-2;
  }
}

.label {
  flex-shrink: 0;
  width: 220px;
  font-size: 14px;
  font-weight: 500;
}

.value {
  width: 100%;
  color: $darkblue;
}

.mobile, .tablet {
  padding: 5px 14px 5px 14px;

  .label,
  .value {
    width: 50%;
    font-size: 12px;
  }
}
