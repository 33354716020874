@import "~assets/scss/variables";

.rightSidebar {
  flex-shrink: 0;
  width: 260px;
  padding: 18px 0;
  margin-left: 15px;
  background-color: $white;
  border-radius: 8px;
}
