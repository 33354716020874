@import "~assets/scss/variables";
@import "~assets/scss/mixins";

.wrapper {
  position: fixed;
  width: 100vw;
  max-height: calc(100vh - 80px);
  left: 0;
  bottom: 0;
  padding-top: 25px;
  border-radius: 14px 14px 0 0;
  background-color: $white;
  z-index: 9999;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 37px;
    height: 5px;
    top: 10px;
    left: calc(50% - 18px);
    border-radius: 3px;
    background-color: $gray-text;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: $darkblue;
  border-bottom: solid 1px $gray;
}

.overlay {
  @include overlay();
}
