@import "~assets/scss/variables";

.reconciliationAct {
  width: 100%;
  display: flex;
}

.pageHeader {
  justify-content: flex-end;
}

.form {
  width: 400px;
  min-height: 400px;
  padding: 16px;

  &>div {
    margin-bottom: 24px;
  }
}

.mobile {
  & .form {
    width: 100%;
    max-width: 400px;
  }
}