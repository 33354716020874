@import "~assets/scss/variables";

.swiperWrapper {
  width: calc(100vw - 595px);
  border-radius: 3px;
  overflow: hidden;
}

.slides {
  display: flex;
}

.slide {
  display: flex;
  justify-content: center;

  & img {
    max-width: calc(100% - 8px);
  }
}

.caroucel {
  display: flex !important;
  width: 100%;
  padding: 0 48px;
}

.prevButton,
.nextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 44px;
  height: 172px;
  top: 0;
  background-color: $gray;
  z-index: 1;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: calc(100% - 5px);
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 3px;
    background: $white;
    z-index: 1;
  }

  & svg {
    stroke: $darkblue;
    transform-origin: center;
    z-index: 2;
  }

  &:hover {
    background-color: $gray-2;
  }

  &:active {
    background-color: $gray;
  }

  &[class*="swiper-button-disabled"] {
    opacity: 0.4;
    cursor: auto;
  }
}

.prevButton {
  left: 0;
}

.nextButton {
  right: 0;
  transform: scale(-1, 1);
}

.mobile {
  &.swiperWrapper {
    width: 100vw;
  }

  & .caroucel {
    padding: 0;
  }

}

.tablet {
  &.swiperWrapper {
    width: calc(100vw - 290px);
  }

  & .caroucel {
    padding: 0;
  }
}