@import "~assets/scss/variables";
@import "~assets/scss/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
  color: $darkblue;
}

.title {
  margin-bottom: 28px;
  font-size: 43px;
  font-weight: 700;
}

.subTitle {
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 500;
}

.item {
  display: flex;
  justify-content: space-between;
  width: 348px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;

  & div:first-child {
    color: $gray-text;
  }
}

.mobile {
  align-items: flex-start;
  padding: 50px 10px;

  & .title {
    margin-bottom: 10px;
    font-size: 22px;
  }

  & .item {
    width: 100%;
    margin-bottom: 15px;
    font-size: 14px;
  }
}

.tablet {
  & .title {
    margin-bottom: 20px;
    font-size: 32px;
    font-weight: 600;
  }

  & .item {
    width: 348px;
  }
}