@import "~assets/scss/variables";

.cart {
  display: flex;
}

.body {
  display: flex;
  min-height: calc(100vh - 237px);
}

.cartHeader {
  align-items: center;
  justify-content: space-between;
}

.countProducts {
  margin-top: 4px;
}

.mainBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 1;
  width: 100%;
  border-right: solid 1px $gray;
}

.asideBlock {
  flex-shrink: 0;
  width: 260px;
  padding: 18px 0;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  background-color: $gray-2;

  & .cell {
    font-weight: 600;
  }
}

.tableBody {
  max-height: calc(100vh - 434px);
  overflow: hidden;
}

.cell {
  width: 100%;
  flex-shrink: 1;
  padding: 0 18px;
  color: $darkblue;
  font-weight: 500;

  &:nth-child(2) {
    font-weight: 600;
  }

  &:nth-child(3) {
    width: 300%;
    overflow: hidden;
    max-height: 31px;
    color: $gray-text;
  }

  &:nth-child(6),
  &:nth-child(8) {
    font-weight: 800;
    white-space: nowrap;
  }

  &:nth-child(9) {
    flex-shrink: 0;
    width: 30px;
  }

  &:last-child {
    flex-shrink: 0;
    width: 50px;
  }
}

.cartListBlock {
  height: calc(100vh - 488px);
}

.tableHeader {
  background-color: $darkblue-1;

  & .cell {
    color: $white;
    font-weight: 500;
  }
}

.productItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-bottom: solid 1px $gray-2;

  &:nth-child(2n) {
    background-color: $gray-2;
  }
}

.remove {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  cursor: pointer;

  & svg {
    fill: $gray-text;
  }
}

.removeBulk {
  height: 36px;
  border: solid 1px $gray-1;
  background: none;
  color: $darkblue;

  & svg {
    fill: $darkblue;
  }

  &:hover {
    color: $white;

    & svg {
      fill: $white;
    }
  }
}

.emptyCart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 249px);
  padding: 20px;
}

.emptyCartImg {
  width: 275px;
  height: 275px;
  margin-bottom: 27px;
  background: url(./emptyCart.png) no-repeat;
}

.emptyCartTitle {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  color: $darkblue-1;
}

.emptyCartSubTitle {
  font-size: 14px;
}

.mobile,
.tablet {

  & .tableBody {
    max-height: calc(100vh - 415px);
  }

  & .totalRowBlock {
    display: flex;
    justify-content: space-between;
  }

  & .totalText {
    width: 100%;
  }

  & .totalTextSumm,
  & .totalText {
    margin-right: 10px;
  }

  & .totalQuantity,
  & .totalSumm {
    font-size: 12px;
  }

  & .productItem {
    padding: 7px 14px;
    gap: 16px;
    height: auto;
    font-size: 12px;

    &:nth-child(2n + 1) {
      background-color: $white;
    }

    &:nth-child(2n) {
      background-color: $gray-2;
    }
  }

  .productInfo {
    display: flex;
    flex-direction: column;
    width: calc(100% - 145px);
    min-height: 88px;
    padding: 7px 0;
  }

  & .index {
    margin-right: 6px;
    font-weight: 600;
    color: $darkblue;
  }

  & .brand {
    margin-right: 6px;
    font-weight: 600;
    color: $darkblue;
  }

  & .article {
    color: $orange;
    font-weight: 600;
  }

  & .counterBlock {
    display: flex;
    gap: 10px;
    margin-bottom: 7px;
  }

  & .priceBlock {
    display: flex;
    justify-content: space-between;
  }

  & .price,
  & .summ {
    font-weight: 500;
    color: $darkblue;
    margin-bottom: 3px;
  }

  & .summ {
    font-weight: 800;
  }

  & .quantityBlock {
    color: $darkblue;
  }

  & .remove {
    width: 30px;
    height: 30px;
    background: none;
    border: solid 1px $gray-1;

    & svg {
      fill: $darkblue;
    }
  }
}

.tablet {

  & .productItem {
    align-items: flex-start;
  }

  & .productInfo {
    width: 100%;
  }

  & .counterBlock {
    flex-direction: column;
    width: 285px;
    margin-top: 8px;

    &>div:first-child {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  & .removeBlock {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 8px;
  }
}