@import "~assets/scss/variables";

.bonus {
  margin-bottom: 8px;
}

.top {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 8px;
  color: $darkblue;
}

.diamondIcon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  background: url(./diamondOrange.svg);
}

.summ {
  font-size: 20px;
  font-weight: 700;
  white-space: nowrap;
}

.balanceBlock {
  width: 100%;
  font-size: 15px;

  & span {
    line-height: 24px;
  }
}

.bonusInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  height: 22px;
  padding: 0 8px;
  background-color: $gray-2;
  color: $gray-text;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
}

.bottom {
  margin-left: 30px;
}