@import "~assets/scss/variables";

.orderBlock {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 240px;
  padding: 24px 18px;
  background-color: $gray-2;
  border-radius: 0 0 0 6px;

  & form {
    display: flex;
    width: 100%;
  }
}

.orderTopBlock {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.overflow {
  display: flex;
  gap: 30px;
  width: 100%;
  overflow-y: none;
}

.orderTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 600;
  color: $darkblue;
}

.radio {
  margin-bottom: 15px;
}

.radioDelivery {
  min-height: 40px;
}

.annotation {
  margin-top: 18px;
}

.selectDelivery {
  display: flex;
  gap: 30px;
}

.deliveryButtonBlock {
  display: flex;
  flex-direction: column;
  gap: 19px;


}


.deliveryButton,
.selectAddressButton {
  width: 170px;
  background: none;
  border: solid 1px $gray-1;
  color: $darkblue;
  font-size: 13px;
}

.deliveryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  padding: 0 15px;
  height: 40px;
  border-radius: 4px;

  & svg {
    width: 17px;
    flex-shrink: 0;
    fill: $darkblue-1;
  }

  &:hover {
    background: $darkblue-1;
    color: $white;

    & svg {
      fill: $white;
    }
  }
}


.selectAddressButton {
  & svg {
    fill: $darkblue-1;
  }

  &:hover {
    color: $white;

    & svg {
      fill: $white;
    }
  }

  &:disabled {
    color: $gray-1;

    & svg {
      fill: $gray-1;
    }
  }
}


.addressModal {
  width: 724px;
}

.pay {
  width: 18%;
  flex-shrink: 0;
}

.delivery {
  width: 52%;
  flex-shrink: 0;
}

.comment {
  width: 26%;
  flex-shrink: 0;

  & textarea {
    width: 100%;
    height: 158px;
    padding: 9px 14px;
    border-radius: 7px;
    border: solid 1px $gray-1;
    resize: none;
  }
}

.total {
  width: 325px;
  flex-shrink: 0;
  margin: 36px 0 0 30px;
}

.totalRow {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
}

.totalText,
.totalTextSumm {
  width: 50%;
}

.totalTextSumm {
  color: $darkblue-1;
}

.totalQuantity {
  font-size: 14px;
  font-weight: 800;
  color: $darkblue-1;
}

.totalSumm {
  font-size: 16px;
  font-weight: 800;
  color: $darkblue-1;
  white-space: nowrap;
}

.button {
  width: 100%;
  margin-bottom: 11px;
}

.mobile,
.tablet {
  height: auto;
  z-index: 2;

  & .orderTitle {
    margin-bottom: 10px;
  }

  & .annotation {
    margin-top: 0;
    font-size: 12px;
  }

  & .radio {
    margin-bottom: 0;
  }

  & .pay {
    width: 100%;
    margin-bottom: 20px;
  }

  & .delivery {
    width: 100%;
  }

  & .comment {
    width: 100%;
    margin-bottom: 5px;

    textarea {
      height: 80px;
    }
  }

  & .deliveryButton,
  & .selectAddressButton {
    width: 100%;
    height: 36px;
  }

  & .orderTopBlock {
    position: fixed;
    width: 100%;
    height: auto;
    max-height: 44px;
    left: 0;
    bottom: 90px;
    flex-direction: column;
    padding: 14px 15px;
    border-radius: 0;
    box-shadow: -1px -2px 8px 0 rgba(0, 0, 0, 0.1);
    background-color: $white;
    transition: max-height 0.7s ease-in-out;

    &.noTransition {
      transition: none;
    }
  }

  & .overflow {
    display: block;
    overflow-y: auto;
  }

  & .orderBlockTitle {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 500;
    color: $darkblue;
  }

  & .totalRowBlock {
    display: flex;
    justify-content: space-between;
  }

  & .totalText,
  & .totalTextSumm {
    width: auto;
    margin-right: 10px;

  }

  & .totalQuantity,
  & .totalSumm {
    font-size: 12px;
  }

  & .total {
    position: fixed;
    width: 100%;
    max-height: 90px;
    left: 0;
    bottom: 0;
    padding: 9px 14px;
    margin: 0;
    background-color: $gray-2;
    z-index: 2;
  }

  & .treangle {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 6px;
    right: 9px;
    border-radius: 50%;
    background: url(~assets/img/svg/hideTreangle.svg) no-repeat center center,
      $white;
    transform-origin: center;
    transition: transform 0.1s ease-in-out;
  }

  & .show {
    max-height: calc(100vh - 90px);

    & .treangle {
      transform: rotate(180deg);
    }
  }

  & .payment {
    display: flex;

    &>div {
      width: 50%;
    }
  }

  & .button {
    height: 44px;
    margin: 0;
  }
}

.tablet {
  & .orderTopBlock {
    width: calc(100% - 285px);
  }

  & .total {
    width: calc(100% - 285px);
  }
}

@media screen and (max-width: 1700px) {
  .total {
    width: 200px;
  }
}