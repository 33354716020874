@import "~assets/scss/variables";

.counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80px;
  height: 24px;
  overflow: hidden;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  background-color: $gray-1;

  & button {
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.1s, color 0.1s ease-in-out;

    &:hover {
      background-color: $orange;
      color: $white;
    }
  }

  & input {
    width: 32px;
    height: 24px;
    background-color: $gray;
    border: solid 1px $gray-1;
    border-radius: 3px;
    text-align: center;
  }

  &.mobile {
    height: 30px;

    & input {
      height: 30px;
    }
  }
}
