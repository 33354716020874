@import "~assets/scss/variables";

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  height: 52px;
  margin-top: 10px;

  &.mobile {
    flex-direction: column;
    height: auto;
    padding: 7px;
    margin: 0;
    background-color: $darkblue;
  }
}

.nav {
  display: flex;
  & ul {
    display: flex;
    align-items: center;
    gap: 18px;
    margin-right: 18px;
  }
}

.navItem {
  padding-right: 18px;
  border-right: solid 1px $gray-text;

  & a {
    border: none;
    font-size: 12px;
    font-weight: 500;
    color: $darkblue;
  }
}

.manager {
  display: flex;
  align-items: center;
  gap: 5px;

  & div {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    font-weight: 500;
    color: $darkblue;

    &:before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      background: url(./user.svg) center center no-repeat;
    }
  }

  & .link {
    font-weight: 600;
    color: $darkblue;
    border-bottom: solid 1px $darkblue;
    cursor: pointer;
  }
}

.phoneBlock {
  & a {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    font-weight: 500;
    border: none;

    &:before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      background: url(./phoneDark.svg) center center no-repeat;
    }
  }
}

.mobile {
  .nav {
    flex-direction: column;
    & ul {
      gap: 0;
      margin-right: 0;
    }
  }

  .navItem {
    display: flex;
    align-items: center;
    border-right: 0;
    padding-right: 0;

    &:not(:last-child):after {
      content: "";
      display: block;
      width: 1px;
      height: 14px;
      right: 0;
      top: calc(50% - 7px);
      background-color: $darkblue-2;
    }

    & a {
      display: block;
      padding: 15px 18px;
      border: none;
      font-size: 12px;
      font-weight: 400;
      color: $white-80;
    }

    &:last-child {
      border-right: 0;
    }
  }

  .manager {
    padding: 15px;
    justify-content: center;

    & div {
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 0;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: $white-80;

      &:before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        background: url(./userLight.svg) center center no-repeat;
      }
    }

    & a {
      font-weight: 600;
      color: $white-80;
      border-bottom: solid 1px $white-80;
    }
  }

  .phoneBlock {
    padding: 15px;
    & a {
      font-size: 12px;
      font-weight: 400;
      color: $white-80;

      &:before {
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        background: url(./phoneLight.svg) center center no-repeat;
      }
    }
  }

  & .link {
    color: $white-80;
    border-bottom: solid 1px $white-80;
  }
}

.tablet {
  background-color: $gray;
}