@import "~assets/scss/variables";
@import "~assets/scss/mixins";

.wrapper {
  display: flex;
  align-items: center;
  gap: 18px;
  width: 100%;
  height: 64px;
  padding: 10px 40px;
  background-color: $white;
}

.logo {
  flex-shrink: 0;
  width: 165px;
  display: flex;
  align-items: center;
  border: none;

  & img {
    width: 100%;
  }
}

.menuButton {
  flex-shrink: 0;
  width: 101px;
  height: 44px;
  padding: 11px 18px 11px 38px;
  border-radius: 4px;
  color: $white;
  font-size: 14px;
  background: url(./hamburger.svg) 12px center no-repeat, $darkblue;
  background-size: 22px;
  cursor: pointer;
  transition: background 0.1s ease-in-out;

  &:hover {
    background: url(./hamburger.svg) 12px center no-repeat, $darkblue-2;
  }
}

.userButton {
  position: relative;
  display: none;
  width: 22px;
  height: 44px;
  flex-shrink: 0;
}

.infoBlock {
  display: flex;
  gap: 18px;
}

.infoBlockPopup {
  display: none;
  right: -15px;
  top: 0;
  flex-direction: column;
  gap: 8px;
  justify-content: left;
  align-items: flex-start;
  background-color: $white;
  border-radius: 4px;
  border: solid 1px $gray-1;
  padding: 7px 45px 7px 15px;
  z-index: 1;

  & svg {
    position: absolute;
    right: 17px;
    top: 11px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $darkblue;
  white-space: nowrap;

  &_title {
    margin-bottom: 2px;
    font-size: 10px;
    font-weight: 400;
  }

  &_value {
    font-size: 12px;
    font-weight: 600;

    &.overdueDebt {
      color: $red;
      font-weight: 700;
    }
  }

  & a {
    border: none;
  }
}

.favorite {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  border: none;
  transition: fill 0.1s, stroke 0.1s ease-in-out;

  & svg {
    stroke: $darkblue;
    fill: none;
  }

  &:hover {
    & svg {
      stroke: $orange;
      fill: $orange;
    }
  }
}

.exit {
  display: flex;
  align-items: center;

  & button {
    font-size: 12px;
    line-height: 14px;
    @include link($darkblue);
  }
}

.menuButtonMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 44px;
  height: 44px;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 14px;
  height: 10px;

  & span {
    display: block;
    height: 2px;
    background-color: $darkblue;
    transition: transform 0.1s, opacity 0.1s ease-in-out;
  }

  &.shown {
    & span {
      &:first-child {
        transform: rotateZ(45deg) translate(3px, 1px);
      }

      &:last-child {
        transform: rotateZ(-45deg) translate(4.1px, -2.7px);
      }

      &:nth-child(2) {
        opacity: 0;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .infoBlockPopup {
    position: absolute;
  }

  .infoBlock {
    display: none;
  }

  .userButton {
    display: block;

    &:hover {
      & .infoBlockPopup {
        display: flex;
      }
    }
  }

  .info {
    align-items: flex-start;
  }
}

.mobile,
.tablet {
  flex-direction: column;
  height: auto;
  padding: 0;
  gap: unset;

  & .top {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  & .logo {
    flex-shrink: 0;
    width: 99px;
  }

  & .leftBlock,
  & .rightBlock {
    display: flex;
    align-items: center;
  }

  & .rightBlock {
    justify-content: end;
  }

  & .userInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: left;
    align-items: flex-start;
    padding: 15px;
  }

  & .userButton {
    min-width: 44px;
    height: 44px;

    &:active,
    &:focus {
      background-color: $orange;

      & svg {
        stroke: $white;
        fill: $white;

      }
    }
  }

  & .favorite {
    min-width: 44px;
    height: 44px;

    &:active,
    &:focus {
      background-color: $orange;

      & svg {
        stroke: $white;
        fill: none;
      }
    }
  }
}

.tablet {
  & .top {
    height: 60px;
    padding-right: 8px;
  }
}