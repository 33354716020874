@import "~assets/scss/variables";

.wrapper {
  padding: 10px 22px;
  background-color: $white;
}

.mobile, .tablet {
  padding: 0;
}
