@import "~assets/scss/variables";
@import "~assets/scss/mixins";

.overlay {
  @include overlay();
}

.filter {
  position: fixed;
  left: 7px;
  bottom: 0;
  width: calc(100vw - 14px);
  flex-direction: column;
  padding: 40px 20px 20px 20px;
  background-color: $white;
  border-radius: 6px 6px 0 0;
  z-index: 99999;

}

.filterTitle {
  margin-bottom: 16px;
  font-size: 22px;
  font-weight: 800;
  color: $darkblue-1;
}

.mobile {
  left: 7px;
}

.tablet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  top: 0;
  width: 520px;
  border-radius: 0;
}