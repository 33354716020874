@import "~assets/scss/variables";

.wrapper {
  width: 410px;

  & form {
    width: 346px;
  }
}

.title {
  margin-bottom: 12px;
  font-size: 47px;
  font-weight: 800;
}

.subTitle {
  margin-bottom: 45px;
  font-size: 19px;
  font-weight: 500;
}

.error {
  margin-bottom: 10px;
  color: $red;
}

.input {
  margin-bottom: 20px;
}

.enter {
  width: 100%;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 800;
}
