@import "~assets/scss/variables";

.wrapper {
  // margin-bottom: 30px;

  &.mobile {
    // margin-bottom: 20px;
  }
}



.title {
  margin-bottom: 17px;
  font-size: 22px;
  font-weight: 700;
  color: $darkblue;
}

.withRightText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;

  & .title {
    margin: 0;
  }
}

.subTitle {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 500;
  color: $darkblue;
}

.formBlock {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.mobile {
  & .title {
    font-size: 20px;
    margin-bottom: 10px;
  }
}