@import "~assets/scss/variables";

.balance {
  margin-bottom: 8px;
}

.top {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 8px;
  color: $darkblue;
}

.rubleIcon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  background: url(./ruble.svg);
}

.summ {
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
}

.balanceBlock {
  width: 100%;
  font-size: 15px;

  & span {
    line-height: 24px;
  }
}

.bonusInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  height: 22px;
  padding: 0 8px;
  background-color: $darkblue-2;
  color: $white;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
}

.diamond {
  width: 12px;
  height: 12px;
  background: url(./diamond.svg);
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  font-size: 12px;
  border: none;

  &>div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    padding: 10px;
    border-radius: 4px;
    background-color: $gray;
    color: $red;
    font-weight: 500;
  }
}