@import "~assets/scss/variables";

.close {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  overflow: visible;
  outline: none;
  width: 28px;
  height: 28px;
  top: 21px;
  right: 25px;
  padding: 0.5px 0 0 0;
  border-radius: 100%;
  border: 0;
  font-size: 20px;
  color: $gray-text;
  background-color: $gray-1;
  box-shadow: none;
  opacity: 1;
  transition: all 0.15s ease-out;
  cursor: pointer;
  touch-action: manipulation;

  &:hover {
    color: $white;
    background-color: $orange;
  }

  &.mobile {
    top: 13px;
    right: 14px;
  }
}
