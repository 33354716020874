@import "~assets/scss/variables";

.radio {
  & label {
    position: relative;
    display: flex;
    gap: 8px;
    color: $darkblue;
    cursor: pointer;
    user-select: none;
    transition: font-size 0.1s ease-in-out;

    &:before {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      border: solid 1px $gray-1;
      border-radius: 50%;
      transition: border 0.1s, background 0.1s ease-in-out;
    }

    &:after {
      content: "";
      position: absolute;
      display: block;
      left: 5px;
      top: 5px;
      width: 10px;
      height: 10px;
      flex-shrink: 0;
      border-radius: 50%;
      transition: border 0.1s, background 0.1s ease-in-out;
    }
  }

  &:hover label:before {
    border: solid 1px $darkblue-70;
  }

  & input {
    display: none;

    &:checked ~ label:before {
      border: solid 1px $gray-1;
    }

    &:checked ~ label:after {
      background-color: $darkblue;
    }
  }
}

.titleBlock {
  margin-top: 3px;
}

.subTitle {
  margin-top: 5px;
  color: $gray-text;
}

.error {
  & label {
    color: $red;
    &:before {
      border-color: $red;
    }
  }
}

.mobile {
  & .titleBlock {
    font-size: 12px;
    margin-bottom: 10px;
  }

  & .annotation {
    font-size: 12px;
  }
}
