@import "~assets/scss/variables";

.product {
  display: flex;
  justify-content: space-between;
  gap: 18px;
  padding: 4px;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  flex-shrink: 0;
  border: solid 1px $gray;
  border-radius: 4px;

  & img {
    max-width: 100%;
    max-height: 100%;
  }
}

.noImage {
  width: 90px;
  height: 90px;
  padding: 14px;
  border: solid 1px $gray;
  border-radius: 4px;
}

.info {
  width: 100%;
}

.article {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 6px;
  font-size: 13px;
  font-weight: 600;
  color: $darkblue;

  & span {
    color: $orange;
  }
}

.name {
  color: $gray-text;
  font-size: 12px;
}

.mobile,
.tablet {
  // flex-direction: column;
  width: calc(100% - 44px);
  padding: 7px 7px 7px 14px;
  & .image {
    width: 46px;
    height: 46px;
  }

  .noImage {
    width: 46px;
    height: 46px;
  }

  & .info {
    width: calc(100vw - 164px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
  }

  & .noButtonInfo {
    width: 44px;
    height: 44px;
  }

  & .article {
    margin-bottom: 2px;
    font-size: 12px;
  }

  & .name {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .infoBlock {
    display: flex;
    align-items: center;
  }
}
