@import "~assets/scss/variables";

.product {
  padding: 24px 32px;
}

.top {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 18px;
  margin-bottom: 18px;
}

.caroucelBlock {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
  user-select: none;
}

.noImage {
  width: 370px;
  height: 370px;
  padding: 76px;
}

.thumbsWrapper {
  display: flex;
  flex-direction: column;
  height: 352px;
  margin-right: 15px;
}

.thumbs {
  height: 352px;
}

.thumb {
  width: 80px !important;
  height: 80px !important;
  margin-bottom: 10px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: solid 1px $gray-1;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;

  &>img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &[class*="swiper-slide-thumb-active"] {
    border-color: $orange;
  }
}

.noImageThumb {
  width: 80px;
  height: 80px;
  padding: 10px;
}

.slideWrapper {
  width: 370px;
}

.slide {
  display: block;
  height: 370px !important;
  width: 100%;
  height: 370px;
  border: solid 1px $gray-1;
  border-radius: 4px;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &>img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.prevButton,
.nextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 57px;
  cursor: pointer;

  & svg {
    stroke: $darkblue;
    transform-origin: center;
  }

  &[class*="swiper-button-disabled"] {
    opacity: 0.4;
  }
}

.prevButton {
  margin-bottom: 4px;

  & svg {
    transform: rotate(90deg);
  }
}

.nextButton {
  margin-top: 4px;

  & svg {
    transform: rotate(-90deg);
  }
}

.infoBlock {
  width: 100%;
}

.article {
  margin-top: 22px;
  margin-bottom: 8px;
  font-size: 12px;
}

.brand {
  margin-bottom: 8px;
  font-size: 14px;
  color: $darkblue;
}

.name {
  margin-bottom: 8px;
  font-size: 22px;
  font-weight: 600;
  color: $darkblue;
}

.barcode {
  width: 208px;
}

.tabContent {
  // position: absolute;
  overflow: hidden;
  // height: 210px;
  width: 100%;
}

.mobile, .tablet {
  padding: 0;

  & .top {
    flex-direction: column;
  }

  .caroucelBlock {
    flex-direction: column;
    gap: 15px;
    height: auto;
  }

  .slideWrapper {
    width: 248px;
    height: 248px;
  }

  .slide {
    height: 248px !important;
    border: none;
  }

  & .noImage {
    width: 248px;
    height: 248px;
    padding: 76px;
  }

  & .infoBlock {
    padding: 14px;
  }

  & .thumbsWrapper {
    flex-direction: row;
    width: 100%;
    height: 44px;
    margin: 0;
  }

  .thumbs {
    height: 44px;
  }

  .thumb {
    width: 44px !important;
    height: 44px !important;
  }

  .articlesBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;
  }

  .brand {
    margin: 0;
    font-size: 12px;
  }

  .article {
    margin: 0;
    font-size: 11px;
  }

  .nameBlock {
    display: flex;
    justify-content: space-between;
  }

  .name {
    font-size: 16px;
  }
}