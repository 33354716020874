@import "~assets/scss/variables";

.title {
  margin-bottom: 12px;
  font-size: 47px;
  font-weight: 800;

  &.mobile {
    width: auto;
    padding: 10px;
  }
}

.subTitle {
  margin-bottom: 45px;
  font-size: 19px;
  font-weight: 500;
}

.error {
  margin-bottom: 10px;
  color: $red;
}

.input {
  margin-bottom: 20px;
}

.rememberBlock {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 45px;
}

.passwordRecovery {
  font-size: 14px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.enter {
  width: 100%;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 800;
}

.register {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 346px;
  border-radius: 4px;
  padding: 9px 18px;
  background-color: $gray;
  font-size: 15px;
  font-weight: 500;

  & a {
    border: none;
    color: $orange;
  }
}

.mobile {
  & .title {
    font-size: 32px;
  }

  & .subTitle {
    font-size: 14px;
  }

  & .enter {
    height: 44px;
  }

  .rememberBlock {
    margin-bottom: 25px;
  }
}