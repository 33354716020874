@import "~assets/scss/variables";

.delivery {
  display: flex;
  align-items: center;
  height: 16px;
  padding-left: 26px;
  font-weight: 500;
  white-space: nowrap;

  &.many {
    background: url(./quantityMany.svg) left center no-repeat;
  }

  &.mean {
    background: url(./quantityMean.svg) left center no-repeat;
  }

  &.few {
    background: url(./quantityFew.svg) left center no-repeat;
  }
}
