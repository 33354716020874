@import "~assets/scss/variables";

.legalForm {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.width50,
.width100 {
  margin-bottom: 20px;
}

.width50 {
  width: calc(50% - 12px);
}

.width100 {
  width: 100%;
}

.formGroup {
  margin-bottom: 30px;
}

.formGroupTitle {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 700;
  color: $darkblue;
}

.formBlock {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.bottomBlock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 9px 18px;
  background-color: $gray;
  font-size: 15px;
  font-weight: 500;

  & a {
    border: none;
    color: $orange;
  }
}

.mobile {
  & .width50 {
    width: 100%;
  }

  & .width50,
  & .width100 {
    margin-bottom: 10px;
  }

  & .enter {
    height: 40px;
  }

  & .bottomBlock {
    flex-direction: column;
    margin: 0;
  }
}
