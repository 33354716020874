@import "~assets/scss/variables";

.title {
  margin-bottom: 40px;
  font-size: 43px;
  font-weight: 700;
  color: $darkblue;
}

.subtitle {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 22px;
  color: $darkblue;
}

.reqisiteItem {
  display: flex;
  padding: 5px 10px;
  color: $darkblue;
}

.reqisiteTitle {
  min-width: 200px;
}

.mobile {
  padding: 10px;

  & .title {
    margin-bottom: 15px;
    font-size: 22px;
  }

  & .button {
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
  }

  & .reqisiteItem {
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    margin-bottom: 12px;
    color: $darkblue;
  }

  & .reqisiteTitle {
    min-width: 100%;
    margin-bottom: 6px;
    color: $gray-text;
  }
}
