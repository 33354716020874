@import "~assets/scss/variables";

.body {
  display: flex;
  min-height: calc(100vh - 237px);
}

.favorite {
  width: 100%;
  display: flex;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &>div {
    width: 100%;
  }

  &:nth-child(2n) {
    background-color: $gray-2;
  }
}

.buttons {
  display: flex;
  gap: 10px;
}

.cart {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.noFavorite {
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 270px);
}

.noFavoriteBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 275px;
}

.noFavoriteImage {
  width: 275px;
  height: 275px;
  margin-bottom: 27px;
  background: url(./noFavorite.svg) center center no-repeat;
}

.noFavoriteTitle {
  margin-bottom: 8px;
  color: $darkblue-2;
  font-size: 18px;
  font-weight: 600;
}

.noFavoriteDescription {
  font-size: 14px;
  text-align: center;
}

.mobile {
  & .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &>div {
      width: 100%;
    }
  }

  & .buttons {
    justify-content: flex-end;
    padding: 0 14px 7px 14px;
  }

  & .favoriteButton {
    width: 30px;
    height: 30px;
  }
}

.tablet {
  & .item {

    &>div:first-child {
      width: calc(100% - 90px);

    }

    &>div:last-child {
      width: 90px;
    }
  }

  & .buttons {
    justify-content: flex-end;
    align-items: center;
    padding: 0 14px;
  }

}