@import "~assets/scss/variables";

.wrapper {
  padding: 24px 32px;
}

.title {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 800;
  color: $darkblue;
}

.input {
  margin-bottom: 20px;
}

.textarea {
  margin-bottom: 20px;
  & > div {
    height: 80px;
  }
}

.buttonBlock {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.tablet, .mobile {
  padding: 14px 15px;

  & .buttonBlock {
    button {
      height: 44px;
      width: 100%;
    }
  }
}
