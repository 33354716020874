@import "~assets/scss/variables";

.noImage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;

  & div {
    width: 100%;
    height: 100%;
    background: url(noImage.svg) center center no-repeat;
    background-size: contain;
  }
}
