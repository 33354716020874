@import "~assets/scss/variables";

.profile {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 44px;
  padding: 50px 0;
  min-height: calc(100vh - 250px);
  background-color: $white;
  border-radius: 8px;

  &.mobile {
    flex-direction: column;
    gap: 0;
    padding: 0;
  }
}

.leftBlock,
.rightBlock {
  width: 330px;
}

.centerBlock {
  width: 724px;
}

.tabItem {
  position: relative;
  padding: 8px 20px;
  width: 100%;
  height: 44px;
  margin-bottom: 10px;
  border-radius: 4px;
  color: $darkblue-2;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  cursor: pointer;

  &.active {
    background-color: $orange-light;
    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 4px;
      height: 100%;
      left: 0;
      top: 0;
      background-color: $orange;
      border-radius: 4px 0 0 4px;
    }
  }
}

.mobile {
  & .leftBlock,
  & .centerBlock {
    width: 100%;
  }

  & .tabs {
    display: flex;
    border-bottom: solid 1px $gray-1;
  }

  & .tabItem {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    max-width: 170px;
    margin: 0;
    border-radius: 0;
    text-align: center;
    font-size: 13px;

    &.active {
      border-bottom: solid 2px $orange;
      &:before {
        content: none;
      }
    }
  }

  & .swiper {
    div[class^="swiper-slide"] {
      width: auto;
    }
  }
}
