@import "~assets/scss/variables";
@import "~assets/scss/mixins";

.wrapper {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 18px;
  width: 100%;
  height: 64px;
  padding: 10px 40px;
  background-color: $white;
  border-bottom: solid 1px $gray;

  & > div {
    height: 100%;
  }

  &.mobile {
    align-items: center;
    height: 44px;
    padding: 0;
    gap: unset;
  }
}

.logo {
  flex-shrink: 0;
  width: 165px;
  display: flex;
  align-items: center;
  border: none;
  & img {
    width: 100%;
  }
}

.login {
  @include o-button();
}

.tablet {
  & .login {
    width: 44px;
    height: 43px;
    padding: 0;
    border: none;
  }
}

.mobile {
  & .logo {
    width: 99px;
    padding-left: 15px;
  }

  & .login {
    width: 44px;
    height: 43px;
    border-radius: 0;
    padding: 0;
    border: none;

    &:active, &:focus {
      fill: $white;
    }
  }
}
