@import "~assets/scss/variables";

.register {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px;

  &.mobile {
    padding: 10px;
  }
}

.container {
  width: 724px;
}

.title {
  margin-bottom: 10px;
  font-size: 60px;
  font-weight: 800;
  color: $darkblue-1;
}

.subTitle {
  margin-bottom: 30px;
  font-size: 19px;
  font-weight: 500;
  color: $darkblue-2;
}

.tabs {
  justify-content: space-between;
  & > button {
    width: calc(50% - 12px);
  }
}

.mobile {
  & .container {
    width: 100%;
  }

  & .title {
    font-size: 32px;
  }

  & .subTitle {
    font-size: 14px;
  }

  & .tabs {
    & > button {
      width: calc(50% - 4px);
    }
  }
}
