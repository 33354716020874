@import "~assets/scss/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 430px;
  color: $darkblue;
}

.title {
  margin-bottom: 28px;
  font-size: 43px;
  font-weight: 700;
}

.text {
  margin-bottom: 32px;
  font-size: 14px;
  font-weight: 500;
}

.button {
  max-width: 346px;
  width: 100%;
  font-size: 15px;
  font-weight: 800;
}

.mobile {
  text-align: center;
  padding: 50px 10px;

  & .title {
    font-size: 22px;
  }

  & .item {
    width: 100%;
    margin-bottom: 15px;
    font-size: 14px;
  }
}

.tablet {
  & .title {
    margin-bottom: 20px;
    font-size: 32px;
    font-weight: 600;
  }

  & .item {
    width: 348px;
  }
}