@import "~assets/scss/variables";

.enter {
  //transform: translateY(100px);
  opacity: 0;
  z-index: 0;
}

.enterActive {
  //transform: translateY(0px);
  opacity: 1;
  z-index: 1;
}

.enterDone {
  //transform: translateY(0px);
  opacity: 1;
  z-index: 1;
}

.exit {
  //transform: translateY(0px);
  opacity: 1;
  z-index: 0;
}

.exitActive {
  //transform: translateY(100px);
  opacity: 0;
  z-index: 0;
  position: absolute;
}

.enterActive,
.exitActive {
  transition: opacity 0.1s, transform 0.1s;
}
