@import "~assets/scss/variables";

.favorite {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
  svg {
    fill: none;
    stroke: $gray-text;
    transition: fill 0.1s, stroke 0.1s ease-in-out;
  }

  &:hover {
    svg {
      fill: $darkblue;
      stroke: $darkblue;
    }
  }

  &:focus,
  &.active {
    transform: scale(1.2);
    svg {
      fill: $orange;
      stroke: $orange;
    }
  }
}
