@import "~assets/scss/variables";
@import "~assets/scss/mixins";

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 48px 0 24px 0;
  padding: 0 12px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  color: $darkblue;
}

.order {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 345px;
  height: 138px;
  padding: 18px;
  background-color: $white;
}

.orderTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  color: $darkblue;
}

.orderStatus {
  display: flex;
  align-items: center;
  gap: 12px;
}

.package {
  width: 24px;
  height: 24px;
  background: url(./package.svg) no-repeat;
}

.orderLink {
  display: flex;
  align-items: center;
  gap: 11px;
  font-size: 12px;
  border: none;
}

.document {
  width: 13px;
  height: 17px;
  background: url(./document.svg) no-repeat;
}

.price {
  margin: 0 0 20px 35px;
  font-size: 20px;
  font-weight: 700;
  color: $darkblue;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.number,
.date {
  display: flex;
  flex-direction: column;
  font-size: 12px;

  & span {
    padding-top: 4px;
    color: $darkblue;
  }
}

.status {
  & div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 24px;
    border-radius: 2px;
    color: $white;
  }
}

.swiperWrapper {
  width: calc(100vw - 590px);
  border-radius: 3px;
}

.slide {
  display: flex;
  justify-content: center;
}

.caroucel {
  display: flex !important;
  width: 100%;
  padding: 0 48px !important;
}

.prevButton,
.nextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 44px;
  height: 138px;
  top: 0;
  background-color: $gray;
  z-index: 1;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: calc(100% - 5px);
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 3px;
    background: $white;
    z-index: 1;

  }

  & svg {
    stroke: $darkblue;
    transform-origin: center;
    z-index: 2;
  }

  &:hover {
    background-color: $gray-2;
  }

  &:active {
    background-color: $gray;
  }

  &[class*="swiper-button-disabled"] {
    opacity: 0.4;
    cursor: auto;
  }
}

.prevButton {
  left: 0;
}

.nextButton {
  right: 0;
  transform: scale(-1, 1);
}


.mobile {
  & .center {
    margin: 0;
  }

  & .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
    color: $darkblue;
  }

  & .subtitle {
    font-size: 12px;
  }

  & .swiperWrapper {
    width: 100vw;
  }

  & .caroucel {
    padding: 0 !important;
  }

}

.tablet {
  & .swiperWrapper {
    width: calc(100vw - 290px);
  }

  & .caroucel {
    padding: 0 !important;
  }
}