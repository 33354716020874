@import "~assets/scss/variables";

.input {
  $p: &;
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  border: solid 1px $gray-1;
  border-radius: 4px;
  background-color: $white;
  transition: background-color 0.1s, border 0.1s ease-in-out;

  & svg {
    margin-right: 15px;
    fill: $gray-1;
  }

  & input {
    width: 100%;
    padding: 12px 15px;
    background: transparent;
  }

  &:hover {
    background-color: $gray-2;
  }

  &:focus-within {
    border: solid 1px $blue;
    &:before {
      opacity: 1;
    }
  }

  & textarea {
    width: 100%;
    height: 100%;
    resize: none;
    padding: 12px 15px;
    border-radius: 4px;
  }

  button {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.show {
      svg {
        fill: $darkblue-1;
      }
    }
  }
}

.labelBg {
  display: block;
  position: absolute;
  left: 8px;
  top: -10px;
  padding: 0 8px;
  opacity: 0;
  background: inherit;
  font-size: 12px;
  color: transparent;
  transition: all 0.1s ease-in-out;
  pointer-events: none;
}

.label {
  position: absolute;
  left: 17px;
  top: 11px;
  transition: all 0.1s ease-in-out;
  pointer-events: none;
  font-size: 12px;
  color: $gray-text;
}

.filled {
  .label {
    color: $darkblue;
    transform: translate(-3px, -18px);
  }

  .labelBg {
    opacity: 1;
  }
}

.errorText {
  left: 0;
  bottom: -18px;
  font-size: 11px;
  color: $red;
}

.error {
  border-color: $red;

  .label {
    color: $red;
  }

  &:focus-within {
    border-color: $red;
  }

  & input {
    color: $red;
  }
}
