@import "~assets/scss/variables";

.select {
  position: relative;
  min-width: 165px;
  width: 100%;
  height: 40px;
  color: $darkblue;
  transition: background-color 0.1s, border 0.1s ease-in-out;

  &:hover {
    background-color: $gray-2;
  }

  &.mobile {
    min-width: 140px;
    font-size: 12px;
    font-weight: 400;
  }
}

.input {
  &>div:focus-within {
    border: solid 1px $gray-1 !important;
  }

  & input {
    padding: 12px 30px 12px 15px;
    cursor: pointer;
  }

  &:after {
    content: "";
    position: absolute;
    top: calc(50% - 8px);
    right: 10px;
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    background: url(~assets/img/svg/arrowDown.svg) center center no-repeat;
    pointer-events: none;
  }
}

.select.opened {  
  border-radius: 4px 4px 0 0;

  &:after {
    rotate: 180deg;
  }

  & .dropdown {
    border-radius: 0 0 4px 4px;
  }
}

.count {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50% - 10px);
  right: 30px;
  height: 18px;
  width: 22px;
  border-radius: 9px;
  font-size: 12px;
  line-height: 1;
  color: $white;
  background-color: $orange;
  pointer-events: none;
}

.dropdown {
  position: absolute;
  width: 100%;
  right: 0px;
  top: 38px;
  border-radius: 4px;
  border: solid 1px $gray-1;
  border-top: 0;
  background-color: $white;
}

.dropdownItem {
  display: flex;
  align-items: center;
  
  cursor: pointer;
  transition: background 0.1s ease-in-out;

  & label {
    display: flex;
    padding: 6px 14px;
    color: $white;

    &>div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      border-radius: 2px;
    }
  }

  &:hover {
    background-color: $gray;
  }
}

.checkbox {
  width: 100%;
}

.checkboxAll {
  display: block;
  width: 100%;
  padding: 8px 0;
  border-bottom: solid 1px $gray-1;

  & label {
    color: $darkblue;
  }
}

.color {
  width: 100%;
  text-align: center;
}

.mobile {

  .dropdown {
    position: fixed;
    width: calc(100% - 14px);
    right: 7px;
    top: auto;
    bottom: 0;
    overflow: hidden;
    border-radius: 14px 14px 0 0 !important;
    border: none;
    z-index: 1;
  }

  .dropdownItem {
    height: 50px;
    border-bottom: solid 1px $gray;
  }

  .checkboxAll {
    padding: 0;
    border: none;

    & label {
      font-size: 14px;
    }
  }

  .color {
    width: 160px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    height: 70px;
    padding: 13px 14px;

    & button {
      width: 50%;
    }
  }

  .reset {
    background-color: $gray;
    color: $darkblue;
  }
}