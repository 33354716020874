@import "~assets/scss/variables";

.dateRange {
  position: relative;
}

.datePicker {
  display: none;
}

.inputWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding-right: 8px;
  border: solid 1px $gray-1;
  border-radius: 4px;
  cursor: pointer;
}

.icon {
  width: 40px;
  height: 40px;
  background: url(./calendar.svg) no-repeat center center;
}

.calendar {
  position: absolute;
  top: 44px;
  left: 0px;
  display: flex;
  padding: 16px;
  background-color: $white;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 10px 10px -5px rgba($darkblue-1, 0.25), 0 25px 25px -5px rgba($gray-text, 0.4);
}

.periods {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100px;
  flex-shrink: 0;
  border-right: solid 1px $gray-1;

  & button {
    height: 36px;
    width: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: solid 1px transparent;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    transition: background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;

    &:hover {
      border-color: $orange;
    }

    &.selected {
      background-color: $orange;
      border-color: $orange;
      color: $white;
    }
  }
}

.calendarBlock {
  border: none;
  border-radius: 0;
}



.prevButton,
.nextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  top: calc(50% - 12px);
  cursor: pointer;
  user-select: none;

  & svg {
    stroke: $darkblue;
    transform-origin: center;
    transform: scale(0.8);
    z-index: 2;
  }

  &:disabled {
    opacity: 0.4;
    cursor: auto;
  }

  // &:hover {
  //   //background-color: $gray-2;
  // }

  // &:active {
  //   //background-color: $gray;
  // }
}

.input {
  width: 100%;

  &>div {
    height: 38px;
    border: solid 1px transparent;
    //background: none;

    &:hover {
      //background: none;
    }

    &:focus-within {
      //border: none;
      border: solid 1px transparent;
    }
  }
}

.nextButton {
  right: 0;
  transform: scale(-1, 1);
}

.mobile {
  & .periods {
    width: 80px;
  }
}