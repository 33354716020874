@import "~assets/scss/variables";

.card {
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background-color: $white;
}

.mobile {
  padding: 12px;
}