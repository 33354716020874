@import "~assets/scss/variables";

.input {
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  padding-left: 29px;
  border: solid 1px $gray-1;
  border-radius: 4px;
  background-color: $gray;

  & input {
    width: 100%;
    background: transparent;
  }

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    left: 5px;
    background: url(~assets/img/svg/search.svg) center center no-repeat;
    background-size: 12px;
    opacity: 0.6;
  }

  &:hover {
    background-color: $gray-2;
  }

  &:focus-within {
    border: solid 1px $blue;
    &:before {
      opacity: 1;
    }
  }
}
