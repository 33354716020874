$white: #ffffff; //Белый
$white-80: #ffffffcc; //Белый (80%)
$gray-1: #dfe0e3; //Серый (-1)
$gray: #f4f5f7; //Серый
$gray-2: #f9f9f9; //Серый (+1)
$gray-3: #E9EBF4; //Серый(+2)
$gray-text: #868ea6; //Серый (текст)
$darkblue-1: #252538; //Темно-синий (-1)
$darkblue: #2c2d42; //Темно-синий
$darkblue-2: #383951; //Темно-синий (+1)
$darkblue-70: #383951b3; //Темно-синий (70%)
$orange: #fb8041; //Оранжевый
$orange-80: #fb804180; //Оранжевый (80%)
$orange-light: #fff5e2; //Оранжевый (Светлый)
$blue: #2d618c; //Синий
$blue-light: #eff7ff; //Синий (светлый)
$green: #27b876; //Зеленый
$green-light: #c7ffc0; //Зеленый (светлый)
$red: #ed2d2d; //Красный