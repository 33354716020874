@import "~assets/scss/variables";

.manager {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 32px;
}

.photo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  margin-bottom: 15px;
  border-radius: 4px;
  overflow: hidden;

  & img {
    height: 100%;
  }
}

.info {
  margin-bottom: 15px;
  text-align: center;
}

.name {
  margin-bottom: 7px;
  font-size: 15px;
  font-weight: 600;
  color: $darkblue;
}

.contactsBlock {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  & a {
    padding: 11px 18px 11px 50px;
    font-size: 12px;
    color: $blue;
    border: none;
    transition: background-color 0.1s ease-in-out;

    &:hover {
      background-color: $gray-2;
    }
  }
}

.phone {
  background: url(~assets/img/svg/phone2.svg) 18px center no-repeat;
}

.email {
  background: url(~assets/img/svg/email.svg) 18px center no-repeat;
}

.whatsapp {
  background: url(~assets/img/svg/whatsapp.svg) 18px center no-repeat;
}

.button {
  width: 250px;
}

.mobile {

  & .managerBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .photo {
    width: 46px;
    height: 46px;
    border-radius: 50%;
  }

  & .contactsBlock {
    width: 100%;

    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 44px;
      border-top: solid 1px $gray;
    }
  }

  & .vin {
    width: 100%;
    padding: 13px 14px;
    border-top: solid 1px $gray;
  }

  & .button {
    width: 100%;
    height: 44px;
  }

}