@import "~assets/scss/variables";
@import "~assets/scss/mixins";

.breadcrumbs {
  & ul {
    display: flex;
    align-items: stretch;
    gap: 8px;
    margin-bottom: 10px;
    font-size: 12px;
  }

  & .link {
    color: $darkblue;
    border: none;
  }

  & li {
    display: flex;
    align-items: center;

    &:not(:last-child)::after {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-left: 10px;
      background: url(./arrowRight.svg) center center no-repeat;
    }

    &:first-child .link {
      margin-left: 18px;
    }

    & span {
      color: $gray-text;
    }
  }
}
