@import "~assets/scss/variables";
@import "~assets/scss/mixins";

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 48px 0 24px 0;
  padding: 0 12px;

  & .title {
    font-size: 18px;
    font-weight: 700;
    color: $darkblue;
  }
}

.wrapper {
  display: flex;
  gap: 16px;
}

.card {
  width: 260px;
  height: 92px;

  & a {
    display: flex;
    gap: 12px;
    border: none;
    color: $gray-text;
  }

  &:hover {
    @include boxShadow();
  }

  & .title {
    margin: 2px 0 10px 0;
    font-size: 15px;
    color: $darkblue;
  }

  & .content {
    font-size: 12px;
  }

  & .fileText {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    background: url(./fileText.svg);
  }
}