@import "~assets/scss/variables";

.title {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 44px;
  padding: 14px 24px 14px 14px;
  border-top: solid 1px $gray-1;
  border-bottom: solid 1px $gray-1;
  color: $darkblue;
  font-size: 14px;
}

.arrow {
  & svg {
    stroke: $darkblue;
    transform: rotate(-90deg);
  }
}

.shown {
  & .arrow {
    transform: rotate(180deg);
  }
}

.content {
  //padding: 14px;
}