@import "~assets/scss/variables";

.button {
  position: fixed;
  right: 7px;
  bottom: 72px;
  height: 44px;
  background-color: rgba($darkblue, 0.7);

  & svg {
    stroke: $white;
    transform: rotate(90deg);
  }
}

.mobile {
  bottom: 14px;
}
