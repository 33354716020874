@import "~assets/scss/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 60px;
  color: $darkblue;
}

.title {
  margin-bottom: 28px;
  font-size: 43px;
  font-weight: 700;
}

.subTitle {
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 500;
}

.input {
  margin-bottom: 40px;
}

.buttonBlock {
  width: 100%;
  display: flex;
  justify-content: center;
}

.enter {
  width: 346px;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 800;
}

.mobile {
  padding: 10px;
  & .enter {
    height: 44px;
  }

  & .title {
    margin: 30px 10px;
    font-size: 22px;
  }

  & .subTitle {
    margin-bottom: 15px;
  }

  & .input {
    margin-bottom: 30px;
  }
}
