@import "~assets/scss/variables";

.home {
  display: flex;
  width: 100%;
}

.leftSide {
  width: 260px;
  flex-shrink: 0;
}

.rightSide {
  width: 260px;
}

.center {
  width: 100%;
  margin-left: 15px;
}

.menu {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.menuItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 11px;
  height: 40px;
  margin-bottom: 16px;
  padding-left: 8px;
  border-bottom: none;
  color: $gray-text;
  font-size: 15px;
  cursor: pointer;
}

.settings {
  width: 24px;
  height: 24px;
  background: url(gear.svg);
}

.message {
  width: 24px;
  height: 24px;
  background: url(message.svg);
}

.logout {
  width: 24px;
  height: 24px;
  background: url(logout.svg);
}

.titleBlock {
  padding: 22px;
}

.title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 8px;
  color: $darkblue;
}


.mobile {
  & .center {
    margin: 0;
  }

  & .titleBlock {
    padding: 8px 12px;
    margin-bottom: 16px;
  }

  & .title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
    color: $darkblue;
  }

  & .subtitle {
    font-size: 12px;
  }

  & .cards {
    margin-bottom: 16px;
    padding: 0 12px;
  }

  & .bottom {
    display: flex;
    gap: 8px;
  }
}

.tablet {
  & .cards {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 24px;

    &>div {
      width: 50%;
    }
  }
}