@import "~assets/scss/variables";
@import "~assets/scss/mixins";

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  background-color: $orange-80;

  & svg {
    animation: spinner-border 0.75s linear infinite;
  }
}

@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}
