@import "~assets/scss/variables";

.managerBlock {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 18px 18px 10px 18px;
  color: $darkblue;
}

.photo {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  overflow: hidden;
}

.noavatar {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: $gray-1;
}

.name {
  margin-bottom: 2px;
  font-weight: 600;
}

.title {
  font-size: 12px;
}

.contactsBlock {
  display: flex;
  flex-direction: column;

  & a {
    padding: 11px 18px 11px 50px;
    font-size: 12px;
    color: $blue;
    border: none;
    transition: background-color 0.1s ease-in-out;

    &:hover {
      background-color: $gray-2;
    }
  }
}

.phone {
  background: url(~assets/img/svg/phone2.svg) 18px center no-repeat;
}

.email {
  background: url(~assets/img/svg/email.svg) 18px center no-repeat;
}

.whatsapp {
  background: url(~assets/img/svg/whatsapp.svg) 18px center no-repeat;
}

.vin {
  padding: 6px 18px 18px 18px;
}

.button {
  width: 100%;
}
