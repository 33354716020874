@import "~assets/scss/variables";

.title {
  margin-bottom: 40px;
  font-size: 43px;
  font-weight: 700;
  color: $darkblue;
}

.input {
  width: 100%;
  margin-bottom: 20px;
}

.button {
  width: 346px;
}

.form {
  margin-bottom: 60px;
}

.mobile {
  padding: 10px;

  & .title {
    font-size: 22px;
    margin-bottom: 15px;
  }

  & .button {
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
  }
}
