@import "~assets/scss/variables";

.mainBlock {
  flex-shrink: 1;
  width: 100%;
  min-height: calc(100vh - 248px);
  border-right: solid 1px $gray;
  border-radius: 8px;
  background-color: $white;
  overflow: hidden;

  &.mobile {
    margin-top: 10px;
    border-radius: 4px;
  }
}
