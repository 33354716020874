@import "~assets/scss/variables";
@import "~assets/scss/mixins";

.wrapper {
  position: fixed;
  width: 240px;
  height: 100vh;
  left: -240px;
  top: 0;
  padding: 38px 8px;
  background-color: $darkblue-1;
  transition: left 0.1s ease-in-out;
  z-index: 9999;

  &.mobile {
    width: 100vw;
    height: calc(100vh - 44px);
    left: -100vw;
    top: -26px;
    padding: 8px 8px 70px 8px;
    transform: translateY(70px);
  }

  &.shown {
    left: 0px;
  }
}

.overflow {
  height: 100%;
  overflow-y: auto;
}

.overlay {
  @include overlay();
}

.close {
  position: absolute;
  display: block;
  width: 22px;
  height: 22px;
  right: 10px;
  top: 10px;
  background: url(./closeMenu.svg) center center no-repeat;
  cursor: pointer;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  padding: 17px 15px;

  &>div {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: $orange;
  font-size: 21px;
  font-weight: 700;
  color: $white;
}

.userName {
  font-size: 16px;
  font-weight: 600;
  color: $white;
}

.settings {
  font-size: 12px;
  color: $white-80;
  @include link();
}

.navItem {
  display: flex;
  gap: 15px;
  margin-bottom: 4px;

  & a {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    height: 100%;
    padding: 9px 15px;
    border-radius: 4px;
    font-size: 14px;
    color: $white;
    background-color: $darkblue;
    transition: background 0.1s ease-in-out;

    & svg {
      stroke: $white;
    }

    &:hover {
      background-color: $darkblue-2;
    }

    &.active {
      background-color: $gray-text;
    }
  }
}

.logout {
  @include link();
  margin: 10px;
  color: $white;
}