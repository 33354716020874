@import "~assets/scss/variables";
@import "~assets/scss/mixins";

.orders {
  width: 100%;
  display: flex;
}

.pageHeader {
  justify-content: flex-end;
}

.filter {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 40px;

  &>div {
    display: flex;
    gap: 15px;
  }
}

.filterButtons {
  margin-left: 15px;

  & .button:disabled {
    color: $white;
    background-color: $gray-1;
  }

  & .resetButton {
    color: $darkblue;
    border: solid 1px $darkblue;
    background-color: $white;

    & svg {
      fill: $darkblue;
    }

    &:disabled {
      border: solid 1px $gray-1;
      color: $gray-1;

      & svg {
        fill: $gray-1;
      }
    }
  }
}

.dateRange {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dateTitle {
  color: $darkblue-1;
}

.dateDelimeter {
  width: 10px;
  border-bottom: solid 1px $darkblue-1;
}

.ordersHead,
.orderItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $darkblue;
  border-bottom: solid 1px $gray-2;

  &>div {
    display: flex;
    justify-content: center;

    &:nth-child(1) {
      width: 8%;
      font-weight: 800;
    }

    &:nth-child(2) {
      width: 8%;
    }

    &:nth-child(3) {
      width: 8%;
    }

    &:nth-child(4) {
      width: 15%;
      font-weight: 800;
    }

    &:nth-child(5) {
      width: 15%;
      font-weight: 500;
    }

    &:nth-child(6) {
      width: 15%;
    }

    &:nth-child(7) {
      width: 10%;
      font-weight: 800;
    }
  }
}

.document {
  display: flex;
  align-items: center;
  gap: 6px;

  & svg {
    fill: $darkblue;
  }
}

.ordersHead {
  padding: 8px 18px;
  background-color: $darkblue;

  &>div {
    color: $white;
    font-weight: 500 !important;
  }
}

.orderItem {
  padding: 12px 18px;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  &:nth-child(2n - 1) {
    background-color: $gray-2;
  }

  &:hover {
    background-color: $gray-1;
  }
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 24px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  color: $white;
}

.selectStatus {
  width: 210px;
}

// .select {
//   width: 220px;
// }

// .reactSelectContainer {
//   width: 220px;
// }

// .reactSelect {
//   width: 220px;
// }

.noOrders {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 249px);
  padding: 20px;
}

.noOrdersImg {
  width: 275px;
  height: 275px;
  margin-bottom: 27px;
  background: url(./noOrders.png) no-repeat;
}

.noOrdersTitle {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  color: $darkblue-1;
}

.totalSummBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  left: 20px;
  width: calc(100vw - 318px);
  height: 50px;
  padding: 12px 18px;
  background-color: $gray-3;
  color: $darkblue;

  &>div {
    &:nth-child(1) {
      width: 75%;
    }

    &:nth-child(2) {
      width: 15%;
    }

    &:nth-child(3) {
      width: 10%;
    }
  }
}

.totalSumm {
  font-weight: 800;
  display: flex;
  justify-content: center;
}

.tablet {
  & .document {
    margin-bottom: 5px;
  }

  & .top {
    gap: 18px;
  }

  & .totalSummBlock {
    width: calc(100vw - 296px);
  }

  & .totalSumm {
    justify-content: flex-end;
  }
}

.mobile {
  & .totalSummBlock {
    width: 100%;
  }

  & .totalSumm {
    justify-content: flex-end;
  }
}

.mobile,
.tablet {
  & .filterButton {
    width: 141px;
    border: solid 1px $gray-1;
    background-color: transparent;
    color: $darkblue;
  }

  .filter {
    flex-direction: column;
    padding: 0;
    margin-left: 0;
    background-color: $white;
    border-radius: 6px 6px 0 0;
    z-index: 99999;

    & .input {
      margin-bottom: 15px;
    }
  }

  & .inputs {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }



  & .filterButtons {

    margin-top: 16px;
    margin-left: 0;

    & button {
      width: 100%;
      height: 44px;

      &:first-child {
        margin-bottom: 15px;
      }

    }
  }

  & .filterTitle {
    margin-bottom: 16px;
    font-size: 22px;
    font-weight: 800;
    color: $darkblue-1;
  }


  & .selectStatus {
    width: 100%;
  }

  & .orderItemMobile {
    display: flex;
    flex-direction: column;
    padding: 10px 14px;

    &:nth-child(2n-1) {
      background-color: $gray-2;
    }

    & span {
      color: $darkblue;
      font-weight: 600;
    }
  }

  & .top {
    display: flex;
    margin-bottom: 7px;

    &>div {
      &:first-child {
        width: 100%;
      }

      &:last-child {
        flex-shrink: 0;
        width: 70px;
      }
    }
  }

  & .orderInfo,
  & .orderCount {
    margin-bottom: 5px;
  }

  & .bottom {
    display: flex;

    &>div {
      &:first-child {
        width: 100%;
      }

      &:last-child {
        flex-shrink: 0;
        width: 134px;
      }
    }
  }

  & .status {
    height: 20px;
    width: 134px;
    border-radius: 3px;
  }
}