@import "~assets/scss/variables";
@import "~assets/scss/mixins";

.goBack {
 
  & button {
    display: flex;
    align-items: center;
    width: 80px;
    border: none;
    font-size: 12px;
  }

  & .icon {
    width: 44px;
    height: 44px;
    background: url(./arrowLeft.svg) center center no-repeat;
  }
}

.mobile {
  background-color: $gray;
}
