@import "~assets/scss/variables";

.title {
  margin-bottom: 40px;
  font-size: 43px;
  font-weight: 700;
  color: $darkblue;
}

.subtitle {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 22px;
  color: $darkblue;
}

.contractItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px 15px;
  background-color: $gray-2;
  border-radius: 4px;
  font-size: 14px;
  color: $darkblue-1;
}

.contractText {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 7px;
  font-size: 14px;
}

.date span {
  font-size: 12px;
  color: $gray-text;
}

.download {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  & svg {
    fill: $gray-text;
    transition: fill 0.1s ease-in-out;
  }
}

a.download {
  
  &:hover {
    & svg {
      fill: $darkblue-1;
    }
  }
}

span.download {
  opacity: 0.3;
}

.mobile {
  padding: 10px;

  & .title {
    margin-bottom: 15px;
    font-size: 22px;
  }
}
