@import "~assets/scss/variables";

.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: stretch;
  border: solid 1px $gray-1;
  border-radius: 4px;
  padding: 4px;
  background: url(~assets/img/svg/search.svg) 9px center no-repeat;

  input {
    height: 34px;
    width: 100%;
    margin-left: 25px;
    margin-right: 13px;
    padding: 0 10px;
    font-size: 14px;
  }
}

.rightBlock {
  display: flex;
  align-items: center;
}

.searchButton {
  height: 36px;
}

.spinner {
  right: 80px;
  width: 50px;
  left: auto;
}

.select {
  width: 165px;
  height: 26px;
  margin-right: 13px;
}

.dropdownItem {
  padding: 13px 14px;
  font-size: 14px;
  color: $darkblue;

  &:not(:last-child) {
    border-bottom: solid 1px $gray;
  }

  &:active,
  &.active {
    background-color: $green-light;
  }
}

.suggestions {
  position: absolute;
  left: 0;
  top: 45px;
  width: 100%;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  background-color: $white;
  border: solid 1px $gray-1;
  border-radius: 4px;
  z-index: 2;
}

.suggestionItem {
  padding: 8px;

  &:not(:last-child) {
    border-bottom: solid 1px $gray-1;
  }

  overflow: hidden;
  font-size: 14px;
  transition: background-color 0.1s ease-in-out;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;

  &:hover {
    background-color: $gray-2;
  }

  & span {
    color: $darkblue;
  }

  & strong {
    font-weight: 600;
  }
}

.selectMobile {
  position: relative;
  height: 30px;
  padding: 0 27px 0 4px;
  margin-right: 7px;
  border-radius: 0 4px 4px 0;
  white-space: nowrap;
  font-size: 11px;
  color: $darkblue;
  background-color: $gray;

  &:after {
    content: "";
    position: absolute;
    right: 7px;
    top: calc(50% - 8px);
    display: block;
    width: 16px;
    height: 16px;
    background: url(./arrowDown.svg) center center no-repeat;
  }
}

.cleanSearch {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  right: 96px;
  border-radius: 50%;
  background-color: $gray-1;
  color: $gray-text;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.1s, color 0.1s ease-in-out;

  &:before {
    content: "×";
  }

  &:hover {
    background-color: $darkblue;
    color: $white
  }
}

.mobile,
.tablet {
  position: relative;
  padding: 7px;
  border: none;
  border-radius: 0;
  background-color: $darkblue;

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    left: 15px;
    top: 13px;
    background: url(~assets/img/svg/search.svg) center center no-repeat;
  }

  input {
    height: auto;
    margin: 0 7px 0 0;
    padding-left: 27px;
    border: solid 1px $gray-1;
    border-radius: 4px;
    background-color: $gray;
    font-size: 12px;
  }

  & .searchButton {
    border-radius: 4px;
  }

  & .cleanSearch {
    right: 58px;
  }
}

.tablet {
  &:before {
    left: 20px;
    top: 20px;
  }

  & .suggestions {
    top: 60px;
  }

  input {
    height: 44px;
    padding: 0 40px;
    margin: 0;
    border: none;
    border-radius: 4px 0 0 4px;
    font-size: 14px;
    background-color: $white;
  }

  .rightBlock {
    padding-right: 5px;
    border-radius: 0 4px 4px 0;
    background-color: $white;
  }

  .cleanSearch {
    right: 100px;
  }
}