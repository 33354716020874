@import "~assets/scss/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 430px;
  color: $darkblue;
}

.container {
  text-align: center;
}

.title {
  margin-bottom: 28px;
  font-size: 43px;
  font-weight: 700;
}

.text {
  margin-bottom: 32px;
  font-size: 14px;
  font-weight: 500;
}

.button {
  max-width: 346px;
  width: 100%;
  font-size: 15px;
  font-weight: 800;
}

.mobile {
  padding: 10px;
  & .title {
    font-size: 22px;
  }
}