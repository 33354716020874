@import "~assets/scss/variables";
@import "~assets/scss/mixins";

.item {
  width: 100%;
  max-width: 33%;
  height: 94px;
  border: solid 2px;
  border-radius: 4px;
  color: $darkblue;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  display: inline-block;
  padding: 4px 22px;
  font-size: 13px;
  font-weight: 600;
  border-radius: 0 0 4px 0;
}

.delivery {
  font-size: 12px;
  padding-right: 14px;
}

.byPrice {
  border-color: $green-light;

  & .label {
    background-color: $green-light;
  }
}

.byDelivery {
  border-color: $blue-light;

  & .label {
    background-color: $blue-light;
  }
}

.byAnalogue {
  border-color: $orange-light;

  & .label {
    background-color: $orange-light;
  }
}

.body {
  display: flex;
  gap: 6px;
  padding: 12px;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  flex-shrink: 0;
  border: solid 1px $gray;
  border-radius: 4px;

  & img {
    max-width: 100%;
    max-height: 100%;
  }
}

.noImage {
  width: 46px;
  height: 46px;
  border: solid 1px $gray;
  border-radius: 4px;
}

.productData {
  width: 100%;
}

.infoBlock {
  display: flex;
  gap: 8px;
  font-size: 15px;

  & span {
    color: $orange;
  }
}

.priceBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  font-size: 20px;
  font-weight: 600;
}

.buyBlock {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.quantity {
  margin-right: 12px;
  color: $gray-text;
  font-size: 12px;
}

.mobile,
.tablet {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
  max-width: 100%;
  height: 44px;
  padding: 7px 14px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid 1px $gray !important;
  border-radius: 0;

  .leftBlock {
    width: 100%;
  }

  .label {
    padding: 0 3px;
    border-radius: 2px;
    font-size: 11px;
    font-weight: 400;
    margin-bottom: 2px;
  }

  .body {
    padding: 0;
    gap: 0;
  }

  .productData {
    display: flex;
  }

  .infoBlock {
    font-size: 12px;
    font-weight: 400;
  }

  .priceBlock {
    flex-direction: column;
    align-items: baseline;
    min-width: 85px;
  }

  .price {
    margin-bottom: 2px;
    font-size: 12px;
    font-weight: 600;
  }

  .delivery {
    font-size: 11px;
    color: $gray-text;
    font-weight: 400;
  }
}

.tablet {
  height: 54px;
}