@import "~assets/scss/variables";

.shedule {
  display: flex;
}

.shedulesHead,
.sheduleItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $darkblue;
  border-bottom: solid 1px $gray-2;

  &>div {
    display: flex;
    justify-content: center;

    &:nth-child(1) {
      width: 12%;
    }

    &:nth-child(2) {
      width: 12%;
    }

    &:nth-child(3) {
      width: 25%;
      color: $gray-text;
    }

    &:nth-child(4) {
      width: 12%;
    }

    &:nth-child(5) {
      width: 12%;
    }

    &:nth-child(6) {
      width: 12%;
    }

    &:nth-child(7) {
      width: 10%;
    }

    &:nth-child(8) {
      width: 10%;
    }
  }
}

.shedulesHead {
  padding: 8px 18px;
  background-color: $darkblue;

  &>div {
    color: $white !important;
    font-weight: 500 !important;
  }
}

.sheduleItem {
  padding: 12px 18px;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  &:nth-child(2n - 1) {
    background-color: $gray-2;
  }

  &:hover {
    background-color: $gray-1;
  }

  &.expired div{
    color: $red;
  }
}