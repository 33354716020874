@import "~assets/scss/variables";

.head {
  padding: 18px;
  margin-bottom: 15px;
  border-radius: 8px;
  background-color: $white;
}

.body {
  display: flex;
  border-radius: 8px;
}

.title {
  font-size: 22px;
  font-weight: 600;
  color: $darkblue;
  margin-bottom: 6px;

  & .article {
    color: $orange;
  }
}

.info {
  display: flex;
  justify-content: space-between;
}

.topProducts {
  display: flex;
  justify-content: space-between;
  gap: 18px;
  padding: 18px;
}

.searchResult {
  padding: 18px;

  & .title {
    font-size: 20px;
    margin-bottom: 11px;
  }

  & .titleBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.notFind {
  padding: 20px;
  color: $darkblue;
  font-weight: 600;
}

.sort {
  margin-bottom: 5px;
}

.mobile,
.tablet {
  & .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 14px 7px 14px;
    width: 100%;
    margin: 0;

    & .titleBlock {
      width: calc(100vw - 80px);

      &.withoutFilter {
        width: 100%
      }
    }
  }

  & .title {
    width: 100%;
    overflow: hidden;
    margin: 0;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .filter {
    width: 44px;

    & button {
      display: block;
      width: 44px;
      height: 44px;
      background: url(./filter.svg) center center no-repeat;
    }
  }

  & .info {
    font-size: 12px;
  }

  & .topProducts {
    flex-direction: column;
    gap: 0;
    padding: 0;
    border: none;
  }

  & .searchResult {
    padding: 0;

    & .titleBlock {
      padding: 7px;
      border-bottom: solid 1px $gray;
    }
  }

  & .apply {
    padding: 13px 14px;

    & .button {
      width: 100%;
      height: 44px;
    }
  }

}

.mobile {
  & .headWrapper {
    padding: 0 10px;
  }

  & .head {
    border-radius: 4px;
  }
}

.tablet {
  & .head {
    height: 78px;
    margin-bottom: 15px;
  }

  & .body {
    align-items: flex-start;
    padding: 0;
  }

  & .title {
    font-size: 14px;
  }

  & .topProducts {
    flex-direction: column;
    gap: 0;
    padding: 0;
    border: none;
  }


}

@media screen and (max-width: 1366px) {
  .addToCartModal {
    max-width: 580px !important;
  }

  .prodModal {
    max-width: 580px !important;
  }
}