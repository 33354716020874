@import "~assets/scss/variables";
@import "~assets/scss/mixins";

.order {
  display: flex;
}

.orderTop {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  border-bottom: solid 1px $gray;
  & > div {
    width: 100%;
    padding: 20px;
    background-color: $white;
    border-radius: 8px;
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.title {
  margin-bottom: 20px;
  font-size: 19px;
  font-weight: 500;
  color: $darkblue-2;
}

.mainBlock {
  background-color: transparent;
}

.infoItem {
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 14px;

  & > div {
    background-color: $white;
    flex-shrink: 0;

    &:last-child {
      font-weight: 600;
      color: $darkblue;
    }

    &:nth-child(2) {
      flex-shrink: 1;
      width: 100%;
      height: 100%;
      margin: 0 10px;
      border-bottom: solid 1px $gray;
    }
  }
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 20px;
  border-radius: 2px;
  color: $white!important;
}

.deliveryItem {
  display: flex;
  gap: 4px;
  margin-bottom: 17px;
  font-size: 14px;

  & > div:first-child {
    font-weight: 600;
    color: $darkblue;
  }

  & a {
    display: flex;
    align-items: center;
    gap: 6px;
    border: none;
    font-size: 13px;
  }
}

.comment {
  font-size: 14px;
}

.documents {
  padding: 16px 0;
  border-top: solid 1px $gray;
  & .title {
    margin-bottom: 12px;
  }

  & .documentButtons {
    display: flex;
    gap: 45px;
  }
}

.documentButton {
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;

  & svg {
    fill: $darkblue;
  }
}

.products {
  margin-top: 15px;
  border-radius: 8px;
  background-color: $white;
}

.productsTitle {
  padding: 16px 18px;
  & .title {
    margin-bottom: 0;
  }
}

.productsHead,
.productItem,
.productsFoot {
  display: flex;
  justify-content: space-between;
  color: $darkblue;
  border-bottom: solid 1px $gray-2;

  & > div {
    &:nth-child(1) {
      width: 3%;
    }
    &:nth-child(2) {
      width: 13%;
    }
    &:nth-child(3) {
      width: 18%;
      font-weight: 600;
    }
    &:nth-child(4) {
      width: 25%;
      color: $gray-text;
    }
    &:nth-child(5) {
      width: 7%;
    }
    &:nth-child(6) {
      width: 10%;
      font-weight: 800;
    }
    &:nth-child(7) {
      width: 7%;
      font-weight: 800;
    }
    &:nth-child(8) {
      width: 10%;
      font-weight: 800;
    }
  }
}

.productsHead {
  padding: 8px 18px;
  background-color: $darkblue;
  font-weight: 500;

  & > div {
    font-weight: 500 !important;
    color: $white !important;
  }
}

.productItem {
  padding: 12px 18px;
}

.productsFoot {
  padding: 17px 18px;
  background-color: $gray-3;
  border-radius: 0 0 0 6px;
}

.mobile,
.tablet {
  & .treangle {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 8px;
    left: calc(50% - 16px);
    border-radius: 50%;
    background: url(~assets/img/svg/hideTreangle.svg) no-repeat center center,
      $white;
    box-shadow: -1px -2px 8px 0 rgba(0, 0, 0, 0.1);
    transform-origin: center;
    transition: transform 0.1s ease-in-out;
  }

  & .orderTopWrapper {
    position: fixed;
    width: 100%;
    bottom: 45px;
    max-height: 175px;
    left: 0;
    padding-top: 24px;
    z-index: 1;
    transition: max-height 0.1s, bottom 0.1s ease-in-out;
    overflow: hidden;

    &.show {
      max-height: 700px;

      & .treangle {
        transform: rotate(180deg);
      }
    }
  }

  & .orderTop {
    flex-direction: column;
    gap: 0;
    max-height: calc(100vh - 100px);
    padding: 15px;
    overflow-y: auto;
    border: solid 1px $gray-2;
    border-radius: 4px 4px 0 0;
    background-color: $white;
    box-shadow: -1px -2px 8px 0 rgba(0, 0, 0, 0.1);
    & > div {
      padding: 8px 0;
      border-radius: 0;
    }

    & > div:not(:last-child) {
      border-bottom: solid 1px $gray;
    }
  }

  & .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 14px;
    & > span {
      font-size: 12px;
    }
  }

  & .infoItem {
    font-size: 12px;
    margin-bottom: 3px;

    & > div:nth-child(2) {
      border-bottom: none;
    }
  }

  & .deliveryItem {
    flex-direction: column;
    margin-bottom: 4px;
    font-size: 12px;
    gap: 1px;
    & > div:first-child {
      font-weight: 600;
      color: $darkblue;
    }

    & .deliveryButton {
      @include button();
      background-color: transparent;
      color: $darkblue;
      border: solid 1px $gray-1;
    }
  }

  & .documentButtons {
    margin-top: 7px;
  }

  & .comment {
    margin-bottom: 3px;
  }

  & .products {
    margin: 0;
    border-radius: 4px;
  }

  & .productItem {
    padding: 14px;
    gap: 16px;
    font-size: 12px;
    & > div {
      &:nth-child(1) {
        width: 56%;
      }
      &:nth-child(2) {
        width: 45px;
        flex-shrink: 0;
      }
      &:nth-child(3) {
        width: 80px;
        flex-shrink: 0;
      }
    }
  }

  & .index,
  & .name {
    color: $gray-text;
  }

  & .brand {
    font-weight: 600;
  }

  & .article {
    color: $orange;
    font-weight: 600;
  }

  & .summ {
    font-weight: 700;
  }

  & .totalSummBlock {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 45px;
    padding: 0 14px;
    color: $darkblue;
    background-color: $gray-3;
    font-size: 13px;
    z-index: 1;
    transition: bottom 0.1s ease-in-out;
  }

  & .totalSumm {
    font-size: 12px;
    font-weight: 800;
  }
}

.tablet {
  & .orderTopWrapper {
    left: 10px;
    width: calc(100vw - 296px);
  }

  & .orderTop {
    border-radius: 8px 8px 0 0;
    & .title {
      flex-direction: column;
      gap: 5px;
    }
  }

  & .title {
    font-size: 18px;
  }

  & .totalSummBlock {
    left: 10px;
    width: calc(100vw - 296px);
  }
}
