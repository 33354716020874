@import "~assets/scss/variables";

.checkbox {
  & label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    color: $darkblue;
    cursor: pointer;
    user-select: none;
    transition: font-size 0.1s ease-in-out;

    &:before {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      background: $gray;
      border: solid 1px $gray-1;
      border-radius: 4px;
      transition: background 0.1s ease-in-out;
    }
  }

  // &:hover label {
  //   font-size: 13px;
  // }

  &:hover label:before {
    background: url(./CheckGray.svg) center center no-repeat, $gray;
  }

  & input {
    display: none;

    &:checked ~ label:before {
      background: url(./CheckWhite.svg) center center no-repeat, $darkblue;
      border: solid 1px $darkblue;
    }
  }
}

.error {
  & label {
    color: $red;
    &:before {
      border-color: $red;
    }
  }
}
