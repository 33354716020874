@import "~assets/scss/variables";


.brandsHead,
.brandItem {
  display: flex;
  justify-content: space-between;
  color: $darkblue;
  border-bottom: solid 1px $gray-2;

  &>div {
    &:nth-child(1) {
      width: 20%;
    }

    &:nth-child(2) {
      width: 20%;
    }

    &:nth-child(3) {
      width: 60%;
    }
  }
}

.brandsHead {
  padding: 8px 18px;
  background-color: $gray-2;
}

.brandItem {
  padding: 12px 18px;
  cursor: pointer;
}

.button {
  width: 120px;
  padding: 0 10px;
  border: solid 1px $darkblue;
  white-space: nowrap;

  &:hover {
    border: solid 1px $orange;
  }
}

.mobile {
  & .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .brandsHead,
  .brandItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $darkblue;
    border-bottom: solid 1px $gray-2;

    &>div {
      &:nth-child(1) {
        width: 30%;
      }

      &:nth-child(2) {
        width: 50%;
      }

      &:nth-child(3) {
        width: 44px;
      }
    }
  }

  & .buttonMobile {
    width: 44px;
    height: 44px;
    & svg {
      transform: rotateY(180deg);
      transform-origin: center;
      stroke: $darkblue
    }
  }
}