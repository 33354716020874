@import "~assets/scss/variables";
@import "~assets/scss/mixins";

.providerPopup {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 17px;
  bottom: 30px;
  left: 104px;
  width: 370px !important;
  height: 114px;
  padding: 22px;
  border: solid 1px $gray-1;
  background-color: $white;
  border-radius: 4px;
  display: none;
  z-index: 1;
  @include boxShadow();
}

.progressContainer {
  position: relative;
}

.title {
  margin-bottom: 3px;
  font-size: 13px;
  color: $darkblue;
}

.subTitle {
  font-size: 12px;
  color: $gray-text;
  white-space: normal;
}

.progressPercent {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  top: 0;
  left: 0;
  font-size: 14px;
  font-weight: 600;
}

.progress {
  transform: rotate(-90deg);
}

.meter,
.value {
  fill: none;
}

.meter {
  &.green {
    stroke: rgba($green, 0.3);
  }

  &.orange {
    stroke: rgba($orange, 0.3);
  }

  &.red {
    stroke: rgba($red, 0.3);
  }
}

.value {
  stroke-linecap: round;
  stroke-dasharray: 340;

  &.green {
    stroke: $green;
  }

  &.orange {
    stroke: $orange;
  }

  &.red {
    stroke: $red;
  }
}