@import "~assets/scss/variables";
@import "~assets/scss/mixins";

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
}

.spinner-border {
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: text-bottom;
  border: 0.25em solid $orange;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.overlay {
  @include overlay();
}

@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}
