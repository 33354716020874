@import "~assets/scss/variables";

.title {
  margin-bottom: 40px;
  font-size: 43px;
  font-weight: 700;
  color: $darkblue;
}

.subtitle {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 22px;
  color: $darkblue;
}

.addressItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px 15px;
  background-color: $gray-2;
  border-radius: 4px;
  font-size: 14px;
  color: $darkblue-1;
}

.mainAddress label {
  color: $gray-text;
}

.addressText {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
}

.remove {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & svg {
    fill: $gray-text;
    transition: fill 0.1s ease-in-out;
  }

  &:hover {
    & svg {
      fill: $darkblue-1;
    }
  }
}

.showForm {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  width: 350px;
  margin-bottom: 20px;

  & svg {
    fill: $white;
  }
}

.input {
  margin-bottom: 20px;
}

.mobile {
  padding: 10px;

  & .title {
    margin-bottom: 15px;
    font-size: 22px;
  }

  .showForm {
    width: 100%;
    height: 40px;
  }

  & .button {
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
  }
}
