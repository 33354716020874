@import "~assets/scss/variables";

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba($darkblue-1, 0.7);
  z-index: 99;

  & .icon {
    position: fixed;
    top: calc(50vh - 42px);
    width: 88px;
    height: 85px;
    margin: 0 auto 60px;
    overflow: hidden;
    background-color: $orange;
    border-radius: 22px;
  }

  & span {
    display: inline-block;
    position: absolute;
    width: 25px;
    height: 40px;
    background-image: url(~assets/img/svg/anim.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }

  & span:nth-child(1) {
    top: 39px;
    left: -5px;
    animation: popup_icon_1 1s infinite;
  }

  & span:nth-child(2) {
    top: 19px;
    left: 50%;
    margin-left: -12.5px;
    animation: popup_icon_2 1s infinite;
  }

  & span:nth-child(3) {
    top: 39px;
    right: -5px;
    animation: popup_icon_1 1s infinite;
  }
}

@keyframes popup_icon_1 {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, -50%);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes popup_icon_2 {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 50%);
  }

  100% {
    transform: translate(0, 0);
  }
}