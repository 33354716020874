@import "~assets/scss/variables";

.modal {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px;
  min-height: 100vh;
  overflow-y: auto;

}

.content {
  position: relative;
  width: 930px;
  border-radius: 4px;
  background: $white;
  overflow: hidden;
}

.mobile {


  & .content {
    width: calc(100vw - 14px);
    max-width: 700px;
    padding: 15px 0;
  }
}