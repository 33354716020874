@import "~assets/scss/variables";

.activate {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.container {
  width: 724px;
}

.title {
  margin-bottom: 10px;
  font-size: 60px;
  font-weight: 800;
  color: $darkblue-1;
}

.success {
  margin-bottom: 30px;
  font-size: 19px;
  font-weight: 500;
  color: $darkblue-2;
}
