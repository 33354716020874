@import "~assets/scss/variables";

.wrapper {
  padding: 10px 22px;

  & main {
    min-height: calc(100vh - 172px);
  }

}


.mobile {
  padding: 0;

  & main {
    border-radius: 0;
  }
}

.tablet {
  padding: 0 10px;
}