@import "~assets/scss/variables";
@import "~assets/scss/mixins";
.button {
  position: relative;
  width: 68px;
  height: 18px;
  padding: 0 0 0 10px;
  border-radius: 9px;
  border: solid 1px $gray;
  font-size: 12px;
  font-weight: 500;
  color: $darkblue;
  cursor: pointer;
  transition: background 0.1s, color 0.1s, border-color 0.1s ease-in-out;

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: -1px;
    top: -1px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: url(./info.svg) center center no-repeat, $gray;
    transition: background 0.1s ease-in-out;
  }

  &:hover {
    color: $white;
    background: $darkblue-1;
    border-color: $darkblue-1;
    &:after {
      background: url(./info.svg) center center no-repeat, $darkblue;
    }
  }
}

.mobile,
.tablet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  padding: 0;
  background: none;
  border: none;

  &:after {
    position: relative;
    left: 0;
    top: 0;
  }
}
