@import "~assets/scss/variables";

.title {
  padding-bottom: 18px;
  font-size: 18px;
  font-weight: 600;
  color: $darkblue;
}

.caroucel {
  display: flex!important;
  width: 100%;
  height: 110px;
  padding: 0 40px!important;
  border-radius: 4px;
}

.product {
  display: flex;
  align-items: center;
  gap: 18px;
  width: 100%;
  height: 110px;
  padding: 12px;
  border-radius: 4px;
  background-color: $white;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  border: solid 1px $gray;
  border-radius: 4px;
  & img {
    max-width: 100%;
    max-height: 100%;
  }
}

.article {
  font-size: 15px;
  font-weight: 600;
  color: $darkblue;

  & span {
    margin-left: 8px;
    color: $orange;
  }
}

.swiperWrapper {
  display: flex;
  justify-content: center;
}

.swiper {
  display: flex;
}

.prevButton,
.nextButton {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 110px;
  border-radius: 4px;
  background-color: $white;
  transition: background-color 0.1s ease-in-out;
  z-index: 1;

  & svg {
    stroke: $darkblue;
    transform-origin: center;
  }

  &:hover {
    background-color: $gray-2;
  }

  &:active {
    background-color: $gray;
  }

  &[class*="swiper-button-disabled"] {
    opacity: 0.4;
  }
}

.prevButton {
  left: 0;
}

.nextButton {
  right: 0;
  transform: scale(-1, 1);
}

.mobile, .tablet {
  padding: 14px;
  overflow: hidden;
  background-color: $gray;

  & .title {
    padding-bottom: 10px;
    font-size: 14px;
  }

  & .image {
    width: 46px;
    height: 46px;
  }

  & .caroucel {
    width: calc(100vw - 28px);
    height: 62px;
    padding: 0!important;
  }

  & .product {
    gap: 8px;
    width: calc(100% - 4px);
    max-width: 400px;
    height: 62px;
    padding: 8px;
    border-radius: 4px;
    background-color: $white;
  }

  & .info {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .article {
    font-size: 12px;
  }

  & .name {
    font-size: 11px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.tablet {
  & .caroucel {
    width: 100%;
  }
}
