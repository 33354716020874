@import "~assets/scss/variables";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  padding: 0 18px;
  margin-bottom: 15px;
  border-bottom: solid 1px $gray;
  border-radius: 8px;
  white-space: nowrap;
  background-color: $white;
}

.title {
  margin-right: 15px;
  font-size: 22px;
  font-weight: 700;
  color: $darkblue;
}

.content {
  display: flex;
  width: 100%;
}

.info {
  width: 242px;
  flex-shrink: 0;
  padding-left: 16px;
}

.tablet {
  &.header {
    height: 56px;
  }

  & .title {
    font-size: 18px;
  }
}

.mobile {
  &.header {
    height: 44px;
    margin: 0;
    border-radius: 4px;
  }

  & .title {
    font-size: 14px;
  }
}
