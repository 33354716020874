@import "~assets/scss/variables";
@import "~assets/scss/mixins";

.balances {
  margin-top: 24px;
  padding: 24px 16px;
  background-color: $white;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin-bottom: 18px;
  font-size: 18px;
  font-weight: 700;
  color: $darkblue;
}

.table {
  margin-bottom: 8px;
  background-color: $gray-2;
  border-radius: 8px;
  overflow: hidden;
}

.balanceHead,
.balanceItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $darkblue;
  border-bottom: solid 1px $gray-2;

  &>div {
    display: flex;
    justify-content: center;

    &:nth-child(1) {
      width: 25%;
    }

    &:nth-child(2) {
      width: 25%;
    }

    &:nth-child(3) {
      width: 25%;
    }

    &:nth-child(4) {
      width: 25%;
    }
  }
}

.balanceHead {
  padding: 8px 18px;
  background-color: $darkblue;

  &>div {
    color: $white !important;
    font-weight: 500 !important;
  }
}

.balanceItem {
  padding: 12px 18px;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  &:nth-child(2n - 1) {
    background-color: $gray-2;
  }

  &:hover {
    background-color: $gray-1;
  }
}

.incoming {
  color: $green;
}

.outgoing {
  color: $red;
}

.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  color: $darkblue;
  font-size: 15px;
}

.more {
  @include button();
  width: 100%;
  background-color: $white;
  color: $gray-text;
  border: solid 1px $gray-1;
}