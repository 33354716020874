@import "~assets/scss/variables";

.product {
  display: flex;

  &:not(:last-child) {
    border-bottom: solid 1px $gray;
  }
}

.productInfo {
  width: 50%;
}

.offers {
  width: 50%;
  border-left: solid 1px $gray;
}

.productData {
  display: flex;
  gap: 18px;
  width: 50%;
  padding-right: 20px;
  border-right: solid 1px $gray;
}

.showMore {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;

  & button {
    position: relative;
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: calc(50% - 5px);
      width: 10px;
      height: 10px;
      background: url(~assets/img/svg/arrowDown.svg) center center no-repeat;
    }
  }
}

.favorite {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 5px 7px;
  margin-top: 1px;
}

.favoriteButton {
  width: 20px;
  height: 20px;
}

.noFavorite {
  width: 20px !important;
}

.mobile,
.tablet {
  flex-direction: column;

  .productInfo {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  & .productData {
    width: 100%;
    padding: 7px 7px 7px 14px;
  }

  & .image {
    width: 46px;
    height: 46px;
  }

  & .info {
    width: calc(100vw - 144px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  & .article {
    margin-bottom: 2px;
    font-size: 12px;
  }

  & .name {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .offers {
    width: 100%;
  }

  & .infoBlock {
    display: flex;
    align-items: center;
  }

  .favorite {
    align-items: center;
    width: 44px;
    height: 44px;
  }
}