@import "~assets/scss/variables";
@import "~assets/scss/mixins";
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  height: 40px;
  border-radius: 4px;
  padding: 0 18px;
  background-color: $orange;
  color: $white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.1s, background 0.1s ease-in-out;

  & svg {
    transition: fill 0.1s ease-in-out;
  }

  &:hover {
    background-color: $darkblue-1;
  }

  &:active {
    background-color: $darkblue-2;
  }

  &.mobile {
    height: 30px;
    padding: 7px 12px;
  }

  &:disabled {
    background-color: inherit;
    color: $gray-1;
  }
}
