@import "~assets/scss/variables";

.favorite {
  margin-bottom: 8px;
}

.top {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  color: $darkblue;

  & span {
    font-size: 15px;
    white-space: nowrap;
  }
}

.heartIcon {
  width: 24px;
  height: 24px;
  stroke: $orange;
  fill: $orange;
}

.bottom {
  margin-left: 30px;
}

.mobile,
.tablet {
  & .top {
    margin-bottom: 0;
  }
}